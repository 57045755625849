import Vue from "vue";
import VueRouter from 'vue-router'
import home from '../view/home.vue'
import News from '../view/News.vue'
import About from '../view/About.vue'
import Product from '../view/Product.vue'
import Service from '../view/Service.vue'
import Example from '../view/Example.vue'
import strategy from '../view/strategy.vue'
Vue.use(VueRouter)
const routes = [

  {
    path:"/home",
    component:home,
    name:"home",

  },
  {
    path:"/News",
    component:News,
    name:"News",

  },
  // {
  //   path:"/strategy",
  //   component:strategy,
  //   name:"strategy",
    
  // },
  {
    path: "/Example",
    component: Example,
    name: "Example",

  },
  {
    path: "/About",
    component: About,
    name: "About",

  },
  {
    path:"/Product",
    component:Product,
    name:"Product",

  },
  {
    path: "/Service",
    component: Service,
    name: "Service",

  },

  {
    path: '*',
    redirect: '/home'
  }


]

const router = new VueRouter({
  mode: 'hash',

  routes
})
export default router