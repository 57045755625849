import note from '../note.vue'
export default {
    methods: {
        link() {
            this.$emit('change','example2')
            // window.location.href="https://www.starquant.com.cn/#/Example?example=example2"
            // // this.$router.push({
            // //     path: '/Example',
            // //     query:{
            // //         example:'example2'
            // //     },
            // // })
            // location.reload();
        },
    },
    components:{
        note
    }
};
