<template>
  <div class="echa">
    <div class="echa-margin">
      <div class="echa-title" v-if="ech.strategyName.split('•').length>1">
        {{ech.strategyName.split('•')[0]}}<span style="font">•</span>{{ech.strategyName.split('•')[1]}}
      </div>
      <div class="echa-title" v-else>
        {{ech.strategyName}}
      </div>
      <div ref="echa" class="echas"></div>
      <div class="echa-bot">
        <div class="bot-item">
          <div>近三年收益</div>
          <div>{{ech.threeYearProfit.toFixed(2)}}%</div>
        </div>
        <div style="width:0;height:36px;border-right:1px solid #f3f3f3;margin-top:10px"></div>
        <div class="bot-item">
          <div>近一个月收益</div>
          <div>{{ech.monthProfit.toFixed(2)}}%</div>
        </div>
        <div style="width:0;height:36px;border-right:1px solid #f3f3f3;margin-top:10px"></div>
        <div class="bot-item">
          <div>近一周收益</div>
          <div>{{ech.weekProfit.toFixed(2)}}%</div>
        </div>
      </div>
    </div>
    <BaseHover :message="message" v-show="!!message" style="position:absolute" :style="{left:left+'px',top:top+'px'}"></BaseHover>
    <BaseAlert @close="close" v-if="showalert"></BaseAlert>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import BaseHover from "./BaseHover.vue"
import BaseAlert from "./BaseAlert.vue"
export default {
  data(){
    return{

      message:'',
      left:0,
      top:0,
      showalert:false,
      myChart:'',
    }
  },
  props:{
    ech:{
      type:Object,
      default:()=>{}
    }
  },
  components:{
  BaseHover,BaseAlert
},
  methods:{
  al(){
    this.showalert=true
  },
  close(){
    this.showalert=false
  },
  showtip(event){
    var e = event || window.event;
       var scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
       var scrollY = document.documentElement.scrollTop || document.body.scrollTop;
       this.left = e.pageX || e.clientX + scrollX;
       this.top = e.pageY || e.clientY + scrollY;
    this.message=this.ech.strategyName

  },
  hidetip(){
    this.message=''
  },
    setdate(date){
      
      return (new Date(date*1000)).getFullYear()+'-'+(Number((new Date(date*1000)).getMonth())+1)+'-'+(new Date(date*1000)).getDate()
    },
    setdate2(){
      return
    },
    setdata(data){
      return data.toFixed(2)
    },
    setchart(){
    var dates= this.ech.dateList.map(this.setdate);
    var max=Math.ceil(this.ech.dateList.length/4)
    console.log(max)
  this.myChart = echarts.init(this.$refs.echa,null,{
    width: 319,
    height: 200,
    devicePixelRatio: 2.5
  });
  this.myChart.setOption({
  tooltip: {
  formatter: function(params) {
  　　var result ='<div>'+params[0].axisValue+'</div>'
  　　params.forEach((item)=>{
  　　　　result +=  '<div style="white-space: normal; word-break: break-all;"><span style="display:inline-block;margin-right:4px;width:10px;height:10px;background-color:'+ item.color+';"></span>' + '<span">'+item.seriesName+'</span>'+ '<span style="margin-left: 12px; float: right;">'+item.data+'%</span></div>'
  　　})
    result='<div class="toolDiv">'+result+'</div>'
  　　return result
  },
    trigger: 'axis',
    show:true,
  },
  legend:{
    show:true,
    left:'1%',
    // orient: "vertical",
    top:'1%',
    itemWidth:5,
    itemHeight :7,
    textStyle:{
      color:'#b6b7b9',
    }
  },
  xAxis: {
    data:dates,
    // splitNumber:4,
    splitLine: {
              //x网格样式
              show: true,
              lineStyle: {
                color: ['#cecece'],
                type: "dashed",
                width: "1",
              },
              interval: (index, value) => {
                return index<dates.length-1&&(index%max==0);
              },
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },

    axisLabel: {
      fontSize:10,
      color:'#cecece',
      // hideOverlap:true,
      interval: (index, value) => {
                return index==max*1||index==max*1*2||index==max*1*3;
      },
      // showMinLabel:false,
      // showMaxLabel:false,
    }
  },
  yAxis: {
    type:'value',
    splitLine: {
              //x网格样式
              show: true,
              lineStyle: {
                color: ['#cecece'],
                type: "dashed",
                width: "1",
              },
    },
     axisLine: {
      show: false
    },
    position:'right',
    axisLabel:{
      formatter: '{value} %',
      fontSize: 10,
      margin: 8,
      color:'#cecece'
    },
    splitNumber:3,   
    axisTick:{
        show:false
    },
    

  },
   grid: {
       left:10,
       top:30,
      width:250,
      height:140,
    },
    
  series: [
    {
      smooth:true,
      name: this.ech.indexName,
      type: 'line',
      data: this.ech.indexProfit.map(this.setdata),
      symbol:'circle',
      symbolSize:1,
      itemStyle: {
        color: "rgba(224, 127, 84, 1)"
      },
      lineStyle: {
        width: 1
      },
      emphasis: {
        scale:11,
      }
    },
    {
      smooth:true,
      name: '策略收益率',
      type: 'line',
      data: this.ech.strategyProfit.map(this.setdata),
      symbol:'circle',
      symbolSize:1,
      itemStyle: {
        color: "rgba(74, 99, 244, 1)"
      },
      lineStyle: {
        width: 1
      },
      emphasis: {
        scale:11,
      }
    }
  ]
}, true);
    }
  },
mounted(){

// 绘制图表

  this.setchart()
},
  watch: {
    ech: {
      handler: function (newdata) {
        console.log(newdata)
        if(!!this.myChart){
          this.myChart.dispose();
          this.setchart()
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style scoped>
.echa {
  width: 316px;
  /* height: 319px; */
  background: #f8fcff;
  border: 1px solid #ebf2fa;
}
.echa:hover{
  box-shadow: 1px 1px 4px 2px rgba(209, 222, 238, 0.9);
}
.echa-margin {
  width: 100%;
  height: 100%;
  margin: 0  1px;
}
.echa-title{
  color: #345f81;
  font-size: 15px;
  /* border-bottom: 1px solid #d9d9d9; */
  margin: 16px 7px 0px 15px;
  padding-bottom: 9px;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  min-height: 20px;
  cursor: pointer;
}
.echa-title:hover{
  text-decoration: underline;
  color: #4d63f7;
}
.echas{
  width: 100%;
  height: 200px;
  margin-left: 8px;
}
.echa-bot{
  height: 60px;
  display: flex;
  flex-direction: row;
}
.bot-item{
  width: 33%;
  text-align: center;
  font-size: 11px;
  line-height: 20px;
  margin-top: 5px;
}
.bot-item div:last-child{
  color: #47484a;
  font-size: 14px;
}
</style>