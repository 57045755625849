<template>
<div>
  <div class="xm" ref="imgg">
        <div class="fd" @click="showbig"></div>
        <img :src="src" alt="" ref="src" width="100%" @click="showbig">
  </div>
  <div class="black-bg" v-if="shown" @click="close" v-portal>
        <div style="position:relative;margin:0 auto" ref="clo" :style="{'margin-top':gogo}">
            <div class="close"></div>
            <img :src="src" alt="" class="bg-img" ref="src">    
        </div>
    </div>
</div>
</template>

<script>
export default {
     data(){
        return{
            shown:false,
            ops:{bar:{
                opacity:0,
            }},
            gogo:'100px',
        }
    },
    props:{
        src:{
            default:'',
            type:String,
        },
    },
    methods:{
        close(e){
            if(this.$refs.src.contains(e.target)) return;
            this.shown=false
            this.$emit('change',false)
        },
        showbig(){
          this.shown=true
             this.$nextTick(()=>{
                  this.gogo=window.scrollY+100+'px'
                   if(!!this.$refs.src&&!!this.$refs.clo){
                        this.$refs.src.onload=()=>{
                          var src=this.$refs.src.getBoundingClientRect(),clo=this.$refs.clo;
                          clo.style.width=src.width+'px'
                        }
                   }
               })
        }
    },
    directives: {
    portal: {
      //自定义指令用于外层渲染
      inserted: function (el, binding) {
        var value = binding.value;
        console.log(binding);
        var app = document.querySelector("#app");
        try {
          if (value) {
            typeof value == "string"
              ? document.querySelector(value).appendChild(el)
              : value.appendChild(el);
          } else app.appendChild(el);
        } catch (error) {
          console.log(error);
        }
      },
    },
  },
  mounted(){
  },
}
</script>

<style scoped>
.xm{
    position: relative;
    margin: 20px auto;
    width: 840px;
    cursor: pointer;
}
.fd{
    position: absolute;
    bottom: 0px;
    right:0px;
    width: 51px;
    height: 51px;
    background: url('./img/fdj.png')no-repeat;
}
.black-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table-cell;
    text-align: center;
    z-index: 999999;
    /* overflow: auto; */
}
.bg-img{
    margin: 0 auto;
    /* margin-top:100px ; */
}
.close{
    cursor: pointer;
    width: 39px;
    height: 39px;
    position: absolute;
    right: -35px;
    top: -60px;
    z-index: 999999;
    background: url('./img/guanb.png');
}
</style>