<template>
  <div>
    <div class="bg">
        <div style="width:920px;margin:0 auto">
            <div class="title">WT10多因子量化选股软件正式发布</div>
            <p class="style1">星格量化研究所旗下的WT10多因子量化选股软件面向机构和专业投资者软件，现正式发布。 </p>
            <p class="style1" style="text-indent: 0;padding-left: 2em;">WT10提供多因子策略量化研发模块，打造涵盖全流程、界面操作式的多因子量化工具，支持构建量化多头、市场中性、指数增强和行业轮动等策略，提供了包括设定股票池或行业范围、选择因子、 检验因子有效性、合成综合因子、策略回测分析报告等功能的全流程多因子量化研究工具</p>
           <p class="style1">&gt;&gt;&gt;<a href="https://WT10.wenhua.com.cn" target="_blank">点击这里进一步了解WT10软件
				</a></p>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.bg{
    background: white;
    min-height: 690px;
    padding-bottom: 50px;
}
.title{
    width: 920px;
    margin: 0 auto;
    color: rgb(91, 91, 91);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 50px 0 15px 0;
    border-bottom: 2px solid #ccc;
}
.style1{
    text-align: justify;
    font-size: 15px;
    text-indent: 2em;
    padding-left: 2em;
    color: #444;
}
p{
    padding: 0;
    margin: 15px 0;
}
.style1 a {
    color: #0000ff;
}
.style1 a:visited{
    color: #0000ff;
}
</style>