<template>
<div id="bl-bg">
  <div class="frame">
    <div style="height:24px;background:#315df1;width:100%;">
        <div class="close" @click="guan()">x</div>
    </div>
    <div style="height:20px;padding:35px 0 25px 0;line-height:20px;text-align:center;color:#47484a">下载WT10了解更多产品详情。</div>
    <a href="https://wt10.wenhua.com.cn" target="_blank" style="text-decoration: none;"><div @click="guan()" class="queding">立即下载</div></a>

  </div>
</div>
</template>

<script>
export default {
methods:{
  guan(){

    this.$emit('close',false)
    
  }
}
}
</script>

<style scoped>
.close{
    cursor: pointer;
    line-height: 24px;
    float: right;
    color: white;
    font-size: 18px;
    margin-right: 3px;
    width: 24px;
    text-align: right;
    padding-right: 3px;
}
#bl-bg{
    position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
}
.frame{
  font-size: 16px;
  color: black;
  background: white;
  position: fixed;
  top: 40%;
  left: 40%;
  width: 350px;
  height: 145px;
  z-index: 9999999999;
}
.queding{
  height: 30px;
  width: 90px;
  border-radius: 5px;
  border:1px solid white;
  color: white;
  background: #315df1;
  line-height: 28px;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}
</style>