<template>
<div class="wrap">
    <!-- <div class="title">中证500指数增强策略案例</div>
    <div class="titleTime">2024-05-10</div>
    <div class="line"></div> -->

    <div class="paragraph">
        评判一家公司时，有人看中盈利能力，有人看中成长能力，还有人看中的是资产结构等。那么，如何从量化的角度定义高质量的公司呢？答案是由多个不同维度的指标构成综合因子进行选股。
    </div>
    <div class="paragraph">
        本文通过复现某券商研报中的综合因子，展示WT10多因子量化选股软件（以下简称WT10）的量化研究功能如何在不编写代码的前提下实现多因子量化选股策略。策略来源为：《西部证券-西部证券因子研究系列专题：质量分域下的中证500增强组合》。为简化处理，仅选取部分指标因子如下：
    </div>
    
        <!-- 表格 -->
    <div style="width:100%">
    <div class="table">
        <div class="col">
            <div class="table_head border_left">类别</div>
            <div class="td1 firsttd">财务类-盈利能力</div>
            <div class="td2 firsttd">财务类-成长性</div>
            <div class="td firsttd">财务类-资本结构</div>
        </div>
        <div class="col1">
            <div class="table_head">名称</div>
            <div class="td">ROA_单季</div>
            <div class="td">ROE_单季</div>
            <div class="td">扣非销售净利率_单季</div>
            <div class="td">销售毛利率_单季</div>
            <div class="td">营业收入_单季同比</div>
            <div class="td">归母净利润_单季同比</div>
            <div class="td">资产负债率_最新财报</div>
        </div>
        <div class="col2">
            <div class="table_head">计算方式</div>
            <div class="td">2*净利润_单季/(总资产_最新财报+总资产_上一期财报)</div>
            <div class="td">2*归母净利润_单季/(归属母公司股东权益合计_最新财报+归属母公司股东权益合计_上一期财报)</div>
            <div class="td">扣非归母净利润_单季/营业收入_单季</div>
            <div class="td">(营业收入-营业成本)_单季/营业收入_单季</div>
            <div class="td">(营业收入_单季-营业收入_去年同期单季)/abs(营业收入_去年同期单季)</div>
            <div class="td">(归母净利润_单季-归母净利润_去年同期单季)/abs(归母净利润_去年同期单季)</div>
            <div class="td">总负债_最新财报/总资产_最新财报</div>
        </div>  
    </div>
    </div>

    <div class="first">1、基本参数</div>
    <div class="paragraph">
        首先交代一下策略回测的基本参数：
    </div>
    <div class="paragraph">
        初始资金：10000万元
    </div>
    <div class="paragraph">
        收益基准：中证500
    </div>
    <div class="paragraph">
        时间范围：2011.7.1--2021.7.30
    </div>
    <div class="paragraph">
        计算频率：每月第一个交易日
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片1.png')"></BaseBigimg>
    </div>

    <div class="first">2、选股范围</div>
    <div class="paragraph">
        以中证500作为选股领域，过滤掉ST股票以及当期停牌的股票：
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片2.png')"></BaseBigimg>
    </div>

    <div class="first">3、添加因子</div>
    <div class="paragraph">
        将前文所述因子一一选入因子列表：
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片3.png')"></BaseBigimg>
    </div>

    <div class="paragraph">
        为了剔除异常值、缺失值、以及行业和市值对因子的影响，需要对因子进行数据预处理（即数据清洗），以便于更好地探究数据规律。在添加因子页面，可直接勾选预处理方法实现对因子数据的预处理，本文参考研报对上述因子进行了数据预处理，因子方向默认为系统因子列表中的方向，是否修改需根据后续的分析结果具体判断。
    </div>

    <div class="second">3.1 单因子分析</div>
    <div class="paragraph">
        接下来检验所选因子在选股中是否真实有效。WT10的因子分析提供了从涨跌相关性分析和时效性分析两个方面出发的单因子有效性检验。（算法详见说明书）
    </div>
    <div class="paragraph">
        在单因子分析页面，点击【开始分析】按钮，即可展示该因子的单因子有效性检验结果，通过软件中的分析图表来判断因子是否有效。
    </div>

    <div class="paragraph">
        <span style="font-weight: bold;">3.1.1 涨跌相关性分析</span>
    </div>
    <div class="paragraph">
        IC值代表因子预测股票收益的能力。一般来说IC绝对值越大，因子预测性越高，选股效果越好。
    </div>
    <div class="paragraph">
        在选股日计算因子的RankIC（下称IC）值及其相关衍生指标，分析其预测性、选股稳定性和持续性程度。
    </div>
    <div class="paragraph1">
        <span></span>IC胜率：分析区间内IC大于0的频次占比；
    </div>
    <div class="paragraph1">
        <span></span>IC均值：分析区间内IC的平均值；
    </div>
    <div class="paragraph1">
        <span></span>IC标准差：分析区间内IC值的标准差；
    </div>
    <div class="paragraph1">
        <span></span>均值/标准差：分析区间内IC均值与标准差的比值；
    </div>
    <div class="paragraph1">
        <span></span>累计IC曲线：累计IC曲线的单调上涨趋势度，代表因子对股票上涨预测能力的强弱。
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片4.png')"></BaseBigimg>
    </div>

    <div class="paragraph">
        <span style="font-weight: bold;">3.1.2 时效性分析</span>
    </div>
    <div class="paragraph">
        对于因子IC的相关分析，有效性分析都是基于因子已经表现出来的历史数据和涨跌幅的关系来进行判断的。然而因子的有效性是呈波动变化的，历史上有效不代表当前一定有效，即需要预测因子在当下是否处于有效周期。
    </div>
    <div class="paragraph">
        WT10的因子时效性分析通过因子内部变量对因子表现产生的影响，预测因子当前的有效性周期，以提供因子的时效性参考信息。主要通过以下几个角度综合考量：
    </div>
    <div class="paragraph1">
        <span></span>动量得分：过去12期因子IC均值在分析区间内的得分水平，代表近期因子预测股票涨跌幅能力的强弱，得分越高代表预测能力越强；
    </div>
    <div class="paragraph1">
        <span></span>拥挤度得分：因子拥挤指市场上某因子使用率高导致其涨跌预测能力失效的现象，得分越高代表其拥挤度越低，可用性越强；
    </div>
    <div class="paragraph1">
        <span></span>综合得分：动量得分和拥挤度得分的均值，得分越高代表因子当前的时效性越好。
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片5.png')"></BaseBigimg>
    </div>
    <div class="paragraph">
        进行了因子分析后，需要根据分析结果将无效因子进行剔除。综合IC检验和时效性检验结果，同时考虑因子表征的股票质量信息，选择将销售毛利率_单季因子进行剔除。
    </div>

    <div class="second">3.2 因子相关性</div>
    <div class="paragraph">
        通过上述步骤，已经初步筛选出了一组较有效的因子。但这些因子间可能存在一定的共线性或雷同逻辑，若直接合成综合因子选股会导致选股结果不佳。因此，需要对这些因子进行相关性分析并进一步处理。
    </div>
    <div class="paragraph">
        本文分析因子相关性的基本思路：通过计算因子间的相关系数，剔除冗余的因子，保留同类因子中效果最好的因子。
    </div>
    <div class="paragraph">
        如下图，点击因子列表下方的【单因子相关性】按钮，可计算并展示上述6个因子值间的相关系数和因子IC值间的相关系数表格：
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片6.png')"></BaseBigimg>
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片7.png')"></BaseBigimg>
    </div>

    <div class="paragraph">
        可以看到大类因子中的因子相关性是较高的，比如同属盈利能力类的因子ROA和ROE相关系数均值达到了0.89，IC相关系数达到了0.96，如果将两个因子同时用来合成综合因子进行选股，势必会造成选股策略风格向高盈利类股票倾斜，选出的个股具有较高的一致性，无法做到真正地分散风险。因此，需要考虑是否需要将部分因子进行剔除。
    </div>
    <div class="paragraph">
        综合考虑因子有效性检验以及因子相关性分析结果，选择剔除盈利能力类中的ROA_单季因子、以及成长性中的营业收入_单季同比因子。
    </div>

    <div class="second">3.4 合成因子分析</div>
    <div class="paragraph">
        完成相关性分析后，将剩余4个因子等权相加后合成因子。该合成因子在中证500内的表现如下，IC胜利为65.29%，IC均值为0.032，IC标准差为0.082，均值/标准差为0.392，可见合成因子有效性、稳定性均较好。时效性分析中，从拥挤度得分及综合得分上看，合成因子的可用性也比较强。
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片8.png')"></BaseBigimg>
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片9.png')"></BaseBigimg>
    </div>

    <div class="paragraph">
        将合成因子放到沪深300和全市场中进行检验，看一看在其他选股域中该合成因子是否还会有不错的表现。
    </div>
    <div class="paragraph">
        同样的回测参数，在沪深300和沪深A股中分别构造合成因子，然后进行合成因子检验，有效性检验主要指标如下：
    </div>

    <!-- 表格 -->
    <div style="width:100%">
    <div class="table table1" style="height:170px">
        <div class="col col_first2 firsttd">
            <div class="table_head">选股范围</div>
            <div class="td">中证500</div>
            <div class="td">沪深300</div>
            <div class="td">沪深A股</div>
        </div>
        <div class="col3 col_first2">
            <div class="table_head">IC胜率</div>
            <div class="td">65.29%</div>
            <div class="td">64.46%</div>
            <div class="td">65.29%</div>
        </div>
        <div class="col3 col_first2">
            <div class="table_head">IC均值</div>
            <div class="td">0.032</div>
            <div class="td">0.035</div>
            <div class="td">0.028</div>
        </div>
        <div class="col3 col_first2">
            <div class="table_head">IC标准差</div>
            <div class="td">0.082</div>
            <div class="td">0.111</div>
            <div class="td">0.079</div>
        </div>
        <div class="col3 col_first2">
            <div class="table_head">均值/标准差</div>
            <div class="td">0.392</div>
            <div class="td">0.317</div>
            <div class="td">0.356</div>
        </div>
    </div>
    </div>

    <div class="paragraph">
        从各个选股域的综合检验结果来看，该合成因子在中证500和沪深300中表现都比较突出。
    </div>

    <div class="first">4、策略优化</div>
    <div class="paragraph">
        一个完整的量化交易系统除了选股和构建投资组合外，还应设有系统的策略优化环节，对持仓组合进行权重优化配置以及规避市场剧烈波动所带来的风险，WT10提供的方法有【持仓组合优化】、【大盘择时】、【个股择时】、【中性化处理】，本文使用大盘择时对策略进行优化。
    </div>
    <div class="paragraph">
        大盘择时是指根据参考指数判断看多和看空，结合市场的状态调整策略仓位，回避市场上价格大幅变动所带来的系统性风险，减小收益回撤。
    </div>
    <div class="paragraph">
        这里尝试使用可以反映大盘走势的沪深300指数进行大盘择时，优化条件选择MACD趋势。当满足看多状态时满仓，看空时仓位设置为50%。
    </div>
    <div class="paragraph">
        不进行持仓组合优化/个股择时/中性化处理：
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片10.png')"></BaseBigimg>
    </div>
    
    <div class="first">5、回测报告</div>
    <div class="paragraph">
        构建好合成因子及策略优化系统后，需要将策略放到市场中进行策略回测，考察其选股结果能否在实际的策略中获取超额收益。
    </div>
    <div class="paragraph">
        回测报告页面，选择中证500中综合评分排名前20%的股票纳入持仓池，点击开始计算，即可一键生成全面的策略分析报告，涵盖收益、风险、持仓、归因等各个方面，以便从多维度考察策略情况，理性评估策略的有效性（具体算法详见软件说明书）。
    </div>

    <div class="second">5.1 最新持仓列表</div>

    <div class="paragraph">
        下图为使用上述合成因子进行选股的策略概览情况，包括了策略的主要回测参数以及回测时间范围内策略的整体表现：
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片11.png')"></BaseBigimg>
    </div>
    <div class="paragraph">
        根据策略回测的持仓情况，给出最新一期的调仓决策列表参考：
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片12.png')"></BaseBigimg>
    </div>

    <div class="second">5.2 收益分析</div>
    
    <div class="paragraph">
        首先看一下在未进行大盘择时优化时，策略的收益情况：
    </div>
    
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片13.png')"></BaseBigimg>
    </div>

    <div class="paragraph">
        累计收益曲线将策略收益率与基准指数收益率绘制在一张图表上，可以查看历史不同时点的收益变动，策略能否跑赢基准获得超额收益一目了然。
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片14.png')"></BaseBigimg>
    </div>

    <div class="paragraph">
        从策略的收益曲线以及收益统计表格可见，该中证500选股策略总体收益情况良好，十年总收益达到了128.04%，年化8.52%，相对基准中证500总超额收益54.31%。但由于没有经过大盘择时来控制风险，收益的波动和回撤较大。
    </div>
    <div class="paragraph">
        下面是使用了MACD趋势指标进行大盘择时后的策略收益情况：
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片15.png')"></BaseBigimg>
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片16.png')"></BaseBigimg>
    </div>
    <div class="paragraph">
        经过大盘择时优化的策略总收益从128.04%提升到了189.82%，收益大幅提升，同时也大大降低了波动率，控制了回撤情况，显著保证了策略的稳定性。
    </div>
    <div class="paragraph">
        下面展示的均为大盘择时优化后的策略回测情况。除了总的收益统计表格，WT10还提供了年度收益统计。如下图，按年度计算策略和基准指数的收益统计指标，对策略效果做纵向对比，便于了解策略在每年度的表现效果，再结合各个时点的行情综合分析，优化策略。
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片17.png')"></BaseBigimg>
    </div>
    
    <div class="second">5.3 风险分析</div>
    <div class="paragraph">
        除了收益情况，策略回撤风险也是投资时需要关注的。
    </div>
    <div class="paragraph">
        下图是策略风险分析的回撤曲线，展示了回测十年间的策略与基准指数回撤情况，可见策略回撤控制的较好。
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片18.png')"></BaseBigimg>
    </div>
    <div class="paragraph">
        除了收益与风险，还可以通过行业分布、交易分析、分层分析等角度来综合考量策略。
    </div>

    <div class="second">5.4 收益归因</div>
    <div class="paragraph">
        回测分析时不能只关注策略的收益大小，收益的归因分析也同等重要。
    </div>
    <div class="paragraph">
        策略收益主要来源于哪里？策略中哪些行业配置和风格配置是强有效的，哪些是无效的？知晓了这些才能更好地对收益持续性进行判断。WT10提供了Brinson行业归因分析及Barra风格归因分析，便于用户直接了解策略的收益究竟来源于什么。
    </div>
    <div class="paragraph">
        首先，让我们来看Brinson行业归因。该策略相对于中证500的超额收益为102.97%，其中行业内选股收益为41.74%，行业配置收益为61.16%，策略的行业配置能力及行业内选股能力都比较优秀。
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片19.png')"></BaseBigimg>
    </div>

    <div class="paragraph">
        Barra风格归因主要看策略在十大经典风格上的暴露情况及收益情况，可选超额收益分解和绝对收益分解。下图为策略的超额收益分解情况，策略相对于收益基准的超额收益主要来源于对非线性市值的低配和盈利的高配，而低配杠杆获得了负的超额收益。
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片20.png')"></BaseBigimg>
    </div>

    <div class="first">结论：</div>
    <div class="paragraph">
        1、通过WT10可以快速、便捷的复现券商研报中的多因子策略研究流程和结果。
    </div>
    <div class="paragraph">
        2、策略分析结果与券商研报结果一致。
    </div>
    <div class="paragraph">
        3、WT10可以灵活的实现更多多因子量化策略，制定调仓计划。
    </div>

    <div style="height:20px"></div>
     <!-- <div class="symbolarea">
        <div class="symbol"></div>
        <span class="symboltext">免责说明</span>
    </div> -->
    <note></note>
    <div style="height:25px"></div>
</div>
</template>
<script>
    import note from '../note.vue'
    export default {
        components:{
            note
        }
    };
</script>

<style scoped src="./style.css"></style>
<style scoped src="../common.css"></style>