<template>
    <div class="wrap">
        <!-- <div class="title">沪深300指数增强策略案例</div>
        <div class="titleTime">2024-05-10</div>
        <div class="line"></div> -->

        <div class="first head">一、沪深300指数增强策略简介</div>
        <div class="paragraph">
            本文我们用WT10复现一个沪深300指数增强策略。为什么是指数增强策略呢？为什么是沪深300指数增强呢？下面小编借这个机会，向您简单介绍一下：
        </div>
        <div class="first">1、指数增强策略</div>
        <div class="paragraph">
            指数增强策略，通常是指在一定偏离度和跟踪误差的约束下，通过主动管理手段，以追求相对基准指数超额收益为主要目标的投资策略。相较于被动指数化投资，指数增强策略在收获指数收益的同时，还能够享受到超额收益增厚。而与主观权益类投资策略相比，指数增强策略则具备明确的比较基准，超额收益的稳定性更强，更具长期配置价值。
        </div>
        <div class="paragraph">
            几种常见的指数增强方式：
        </div>
        <div class="paragraph">
            (1)个股或行业权重优化
        </div>
        <div class="paragraph">
            投资者利用主观或量化的方式调整指数成分股或者某些行业的权重，发掘更有投资价值的个股或行业进行超配，降低或完全剔除缺少投资价值的个股或行业。简单地说，就是“对部分看好的股票增加权重，不看好的股票则减少权重，甚至完全去掉。”
        </div>
        <div class="paragraph">
            (2)大盘择时
        </div>
        <div class="paragraph">
            利用基本面、技术面或情绪面指标对未来市场走势进行预判，从而调整资金的整体仓位，预计市场上涨则增加仓位，反之则减仓。
        </div>
        <div class="paragraph">
            (3)其他
        </div>
        <div class="paragraph">
            其他方法还有打新、衍生品交易、现金管理等等，或同时使用以上多种方式进行增强。
        </div>

        <div class="first">2、沪深300指数增强产品</div>
        <div class="paragraph">
            提起沪深300指数，相信大家都不会陌生，它由上海和深圳证券市场中市值大、流动性好的300只股票组成，能够综合反映A股市场的整体表现。截至2021年7月底，沪深300指数总市值占全部A股的50%以上，足见其分量之重。而且，其主要成份股均为行业龙头，基本代表了沪深两市的核心资产。
        </div>
        <div class="paragraph">
            据统计，截至2021年6月底，市场上的指数增强类基金（2021.3.31前成立）规模总计为1296.453亿元。从类别上看，沪深 300
            指数增强基金规模最大，总计达558亿元。另外，沪深300股指期货与期权的存在也使得交易沪深300标的股票可以对冲一部分系统风险，这也是为什么沪深300指数更加受到各公募、私募基金青睐的原因了。
        </div>
        <div class="paragraph">
            了解了以上内容，您是不是也迫不及待的想要构建一个沪深300指数增强策略啦？下面跟着小编的脚步，参考专业券商的金工研报，利用WT10量化功能，快速开启属于您的沪深300指数增强策略之旅吧！
        </div>
        <div class="paragraph">
            本文的参考研报为：《渤海证券--多因子模型研究系列之七：使用多因子框架的沪深300指数增强模型》，采用指数增强的第一种方式，通过WT10量化功能复现其多因子收益策略构建流程，并在此基础上进一步优化策略。
        </div>

        <div class="first">二、复现流程</div>

        <div class="first">1、基本参数</div>
        <div class="paragraph">
            首先交代一下策略回测的基本参数：
        </div>
        <div class="paragraph">
            初始资金：10000万元
        </div>
        <div class="paragraph">
            收益基准：沪深300
        </div>
        <div class="paragraph">
            时间范围：2014.2.24--2021.7.30
        </div>
        <div class="paragraph">
            计算频率：每月第一个交易日
        </div>

        <div class="img_center">
            <BaseBigimg :src="require('./img/图片1.png')"></BaseBigimg>
        </div>

        <div class="first">2、选股范围</div>
        <div class="paragraph">
            设置回测股票池为沪深300，过滤ST股票、停牌股票，其他为默认设置：
        </div>

        <div class="img_center">
            <BaseBigimg :src="require('./img/图片2.png')"></BaseBigimg>
        </div>

        <div class="first">3、选择因子</div>
        <div class="paragraph">
            该研报经过单因子有效性检验，筛选出了5大类，共11个因子。
        </div>
        <div class="paragraph">
            上述研报中所选因子在WT10系统因子库中均可以轻松获取。下表为研报中所选的因子，WT10系统因子库中包含了前8个因子，对于不同期限的累计对数收益率因子，可以参考系统因子中累计收益率因子的代码示例，修改参数后构造自编因子。因子方向默认为因子列表中的方向，是否修改需根据后续的分析结果具体判断。
        </div>

        <!-- 表格 -->
        <div style="width:100%">
            <div class="table">
                <div class="col2">
                    <div class="table_head border_left">因子名称</div>
                    <div class="td firsttd">市净率_最新财报倒数</div>
                    <div class="td firsttd">ROE_单季</div>
                    <div class="td firsttd">营业收入_单季同比</div>
                    <div class="td firsttd">归母净利润_单季同比</div>
                    <div class="td firsttd">ROE_单季同比</div>
                    <div class="td firsttd">月度换手率</div>
                    <div class="td firsttd">季度换手率</div>
                    <div class="td firsttd">年度换手率</div>
                    <div class="td firsttd">累计对数收益率（1-20个交易日）</div>
                    <div class="td firsttd">累计对数收益率（1-60个交易日）</div>
                    <div class="td firsttd">累计对数收益率（1-120个交易日）</div>
                </div>
                <div class="col5">
                    <div class="table_head">因子描述</div>
                    <div class="td">(归属母公司股东权益合计-其他权益工具)_最新财报 / 总市值</div>
                    <div class="td">2*归母净利润_单季 / (归属母公司股东权益合计_最新财报 + 归属母公司股东权益合计_上一期财报)</div>
                    <div class="td">(营业收入_单季 - 营业收入_去年同期单季) / abs(营业收入_去年同期单季)</div>
                    <div class="td">(归母净利润_单季-归母净利润_去年同期单季) / abs(归母净利润_去年同期单季)</div>
                    <div class="td">(ROE_单季 - ROE_去年同期单季) / abs(ROE_去年同期单季)</div>
                    <div class="td">过去21个交易日的月度换手率</div>
                    <div class="td">过去63个交易日的季度换手率</div>
                    <div class="td">过去252个交易日的年度换手率</div>
                    <div class="td">过去20个交易日的指数加权累计对数收益率</div>
                    <div class="td">过去60个交易日的指数加权累计对数收益率</div>
                    <div class="td">过去120个交易日的指数加权累计对数收益率</div>
                </div>
            </div>
        </div>

        <div class="first">4、检验因子有效性</div>
        <div class="paragraph">
            在单因子分析页面，通过涨跌相关性分析和时效性分析两种方式对每个因子的有效性进行检验。
        </div>
        <div class="paragraph">
            根据最新数据，研报中11个因子有效性检验结果如下表：
        </div>

        <!-- 表格 -->
        <div style="width:100%">
            <div class="table">
                <div class="col">
                    <div class="table_head border_left">因子类别</div>
                    <div class="td firsttd">市场估值类</div>
                    <div class="td firsttd">财务类-盈利能力</div>
                    <div class="td1 firsttd">财务类-成长性</div>
                    <div class="td1 firsttd">量价类-换手率因子</div>
                    <div class="td1 firsttd">量价类-涨跌因子</div>
                </div>
                <div class="col1">
                    <div class="table_head">因子名称</div>
                    <div class="td">市净率_最新财报倒数</div>
                    <div class="td">ROE_单季</div>
                    <div class="td">营业收入_单季同比</div>
                    <div class="td">归母净利润_单季同比</div>
                    <div class="td">ROE_单季同比</div>
                    <div class="td">月度换手率</div>
                    <div class="td">季度换手率</div>
                    <div class="td">年度换手率</div>
                    <div class="td">累计收益率（1-20个交易日）</div>
                    <div class="td">累计收益率（1-60个交易日）</div>
                    <div class="td">累计收益率（1-120个交易日）</div>
                </div>
                <div class="col3">
                    <div class="table_head">预测收益能力</div>
                    <div class="td">较好</div>
                    <div class="td">较好</div>
                    <div class="td">较好</div>
                    <div class="td">较好</div>
                    <div class="td">较好</div>
                    <div class="td">较好</div>
                    <div class="td">较好</div>
                    <div class="td">较好</div>
                    <div class="td">较差</div>
                    <div class="td">较差</div>
                    <div class="td">较差</div>
                </div>
                <div class="col3">
                    <div class="table_head">选股区分度</div>
                    <div class="td">一般</div>
                    <div class="td">一般</div>
                    <div class="td">较好</div>
                    <div class="td">较好</div>
                    <div class="td">较好</div>
                    <div class="td">较好</div>
                    <div class="td">较好</div>
                    <div class="td">较好</div>
                    <div class="td">较差</div>
                    <div class="td">较差</div>
                    <div class="td">较差</div>
                </div>
                <div class="col2">
                    <div class="table_head">结果</div>
                    <div class="td2">为保证因子种类多样性，选择保留</div>
                    <div class="td1">保留</div>
                    <div class="td1">保留</div>
                    <div class="td1 td_com">替换为效果更好的系统因子20日涨幅和自编因子60日涨幅</div>
                </div>
            </div>
        </div>

        <div class="first">5、分析因子相关性</div>
        <div class="paragraph">
            对经过单因子检验后保留下来的因子进行因子相关性分析，如下图分析可见，同属于成长性因子的营业收入_单季同比、归母净利润_单季同比和ROE_单季同比，同属于涨跌因子的20日涨幅和60日涨幅，同属于换手率因子的月度换手率、季度换手率和年度换手率的因子相关性均值较大。因子相关性较高，说明因子之间存在多重共线性，不能很好的做到选股风格分散化。
        </div>

        <div class="img_center">
            <BaseBigimg :src="require('./img/图片3.png')"></BaseBigimg>
        </div>

        <div class="paragraph">
            参考研报的处理方式，按照同类因子保留单因子检验效果更好的原则，进行剔除。留下的因子之间相关性较低，能够有效地避免选股结果侧重某一风格的问题，如下图所示。
        </div>

        <div class="img_center">
            <BaseBigimg :src="require('./img/图片4.png')"></BaseBigimg>
        </div>

        <div class="first">6、合成因子分析</div>
        <div class="paragraph">
            经过上述流程，本策略最终选用因子如下：
        </div>

        <!-- 表格 -->
        <div style="width:100%">
            <div class="table table2">
                <div class="col2">
                    <div class="table_head border_left">因子名称</div>
                    <div class="td firsttd">市净率_最新财报倒数</div>
                    <div class="td firsttd">ROE_单季</div>
                    <div class="td firsttd">营业收入_单季同比</div>
                    <div class="td firsttd">月度换手率</div>
                    <div class="td firsttd">20日涨幅</div>
                </div>
                <div class="col5">
                    <div class="table_head">因子描述</div>
                    <div class="td">(归属母公司股东权益合计-其他权益工具)_最新财报 / 总市值</div>
                    <div class="td">2*归母净利润_单季 / (归属母公司股东权益合计_最新财报 + 归属母公司股东权益合计_上一期财报)</div>
                    <div class="td">(营业收入_单季 - 营业收入_去年同期单季) / abs(营业收入_去年同期单季)</div>
                    <div class="td">过去21个交易日的月度换手率</div>
                    <div class="td">过去20个交易日的累计收益率</div>
                </div>
            </div>
        </div>

        <div class="paragraph">
            数据预处理可以直接通过页面操作实现，参考研报对上述因子数据进行预处理，并按照等权方式构造合成因子，合成因子的IC胜率为78.65%，IC均值为0.082，IC标准差为0.130，均值/标准差为0.626，因子的预测能力较为优秀，因子波动比较稳定。合成因子时效性分析中，从拥挤度得分和综合得分表现上看，因子时效性也比较好。
        </div>

        <div class="img_center">
            <BaseBigimg :src="require('./img/图片5.png')"></BaseBigimg>
        </div>
        <div class="img_center">
            <BaseBigimg :src="require('./img/图片6.png')"></BaseBigimg>
        </div>

        <div class="first">7、持仓组合优化</div>
        <div class="paragraph">
            WT10提供了丰富的个股赋权方式，包括不做优化（等权）、单指标赋权优化（含流通市值加权、波动率倒数加权、综合因子分位数加权等8种）、组合赋权优化。根据研报流程，暂不进行优化。
        </div>

        <div class="first">8、回测报告</div>
        <div class="paragraph">
            在沪深300指数成分股内，根据综合评分由高到低，选出前10%的股票纳入持仓池，交易手续费为默认设置生成策略回测分析报告。分析报告首页为策略概览，展示了策略的基本参数设置、权益曲线以及持仓占比图。
        </div>

        <div class="img_center">
            <BaseBigimg :src="require('./img/图片7.png')"></BaseBigimg>
        </div>

        <div class="second">8.1、收益分析</div>
        <div class="paragraph">
            通过收益统计表格和收益曲线，可以看出本策略持续稳定地跑赢了基准指数沪深300。策略总收益为314.28%，年化收益21.08%，相对基准沪深300总超额收益94.97%。同时夏普比率和最大回撤分别为0.67和39.73%，相较于基准的0.29和46.70%均有提升。
        </div>

        <div class="img_center">
            <BaseBigimg :src="require('./img/图片8.png')"></BaseBigimg>
        </div>

        <div class="second">8.2、回撤分析</div>
        <div class="paragraph">
            回撤分析中的回撤曲线展示了回测区间内本策略和基准指数的回撤情况，整体上策略的回撤要小于基准指数的回撤情况。
        </div>

        <div class="img_center">
            <BaseBigimg :src="require('./img/图片9.png')"></BaseBigimg>
        </div>

        <div class="first">9、最新持仓列表</div>
        <div class="paragraph">
            最新持仓列表可以查看本期持仓列表以及调仓操作列表，同时支持加入自选和导出。
        </div>

        <div class="img_center">
            <BaseBigimg :src="require('./img/图片10.png')"></BaseBigimg>
        </div>

        <div class="first">10、策略对比</div>
        <div class="paragraph">
            以上就是小编基于WT10量化功能，对所复现研报进行的策略优化，通过收益和回撤分析结果，可以看出这个策略还是很成功的。
        </div>
        <div class="paragraph">
            读者可能会有一个担心，在研报基础上做的这些优化是否有效？如果不做这些分析和优化，会不会效果更好呢？
        </div>
        <div class="paragraph">
            别担心，小编向您介绍一个很实用的功能——策略对比！
        </div>
        <div class="paragraph">
            将研报中的原策略按照相同设置进行了回测，并运用软件的策略对比功能，将本策略与原策略的回测结果进行对比，如下图所示：
        </div>
        <div class="paragraph">
            可以看出，截至2024.4.23本策略获得总收益258.63%，年化收益13.39%，优于原策略的218.93%和12.09%；本策略的夏普比率和索提诺比率分别为0.40和1.32相较于原策略的0.35和1.15也有提升。
        </div>

        <div class="img_center">
            <img class="img" src="./img/图片11.png" alt="">
        </div>

        <div class="paragraph">
            下图为策略对比结果中的收益曲线，可以更加直观地反映，本策略能够持续优于原策略。
        </div>
        <div class="paragraph">
            通过策略对比的结果，说明按照量化功能的流程对策略进一步分析和优化是有效的。
        </div>

        <div class="img_center">
            <BaseBigimg :src="require('./img/图片12.png')"></BaseBigimg>
        </div>

        <div class="first">三、结论</div>
        <div class="paragraph">
            经过上述研究和复现，小编可以得出以下结论：
        </div>
        <div class="paragraph">
            1.WT10具有丰富多样的因子，数据预处理流程全面且操作简单，可以快速、便捷地实现券商研报中常见的预处理流程。
        </div>
        <div class="paragraph">
            2.运用WT10的多因子策略研究流程，可以对研报因子进行检验和优化，有效提升研究效率。
        </div>
        <div class="paragraph">
            3.WT10的策略对比功能可以高效地对比多个策略，快速实现策略优选。
        </div>
        <div class="paragraph">
            4.WT10可以助您实现更加丰富的多因子量化策略，并制定最新调仓计划。
        </div>

        <div style="height:20px"></div>
         <!-- <div class="symbolarea">
            <div class="symbol"></div>
            <span class="symboltext">免责说明</span>
        </div> -->
        <note></note>
        <div style="height:25px"></div>

    </div>
</template>
<script>
    import note from '../note.vue'
    export default {
        components:{
            note
        }
    };
</script>
<style scoped src="./style.css"></style>
<style scoped src="../common.css"></style>