<template>
  <div v-portal>
    <div class="message">{{message}}</div>
  </div>
</template>

<script>
export default {
props:{
    message:{
        default:'',
        type:String,
    }
},
  directives: {
    portal: {
      //自定义指令用于外层渲染
      inserted: function (el, binding) {
        var value = binding.value;
        var app = document.querySelector("#app");
        try {
          if (value) {
            typeof value == "string"
              ? document.querySelector(value).appendChild(el)
              : value.appendChild(el);
          } else app.appendChild(el);
        } catch (error) {
          console.log(error);
        }
      },
      unbind: function (el) {
        try {
          el.parentNode.removeChild(el);
        } catch (error) {
          console.log(error);
        }
      },
    },
  },
}
</script>

<style scoped>
.message{
    border: 1px solid #dedede;
    padding: 3px 6px;
    background: white;
    color: #2e2e2e;
    font-size: 14px;
}
</style>