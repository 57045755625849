<template>
  <div class="container">
    <div style="width: 1260px; margin: 0 auto; display: flex">
      <div class="tab">
        <div class="tab_title">服务</div>
        <!-- <div class="underline"></div> -->
        <ul>
          <li
            v-for="(item, index) in tab_arr"
            :key="index"
            @click="changeTab(item.index)"
            :class="active_index == item.index ? 'active' : ''"
          >
            <span>{{ item.name }}</span>
            <span v-if="active_index==item.index" style="float:right">></span>
          </li>
        </ul>
      </div>
      <div class="content">
        <div v-if="active_index == 0">
          <div class="mt"><span class="modeNm">渠道一：线上服务</span></div>
          <div class="model_box">
            <div class="box_text">
              <p class="text_indent">线上服务是星格服务用户的主流渠道。</p>
              <p class="text_indent">
                线上服务配备一支专业金融工程师团队，每日14个小时的在线值守，解答投资者提出的各种软件使用问题。
              </p>
              <p class="text_indent">
                每天我们处理上千个用户的提问，上一个年度我们解答了155528个用户的问题。
              </p>
              <p class="text_indent">
                金融工程师团队成员，由具有金融类本科学历、持有期货/证券从业资格证的专业人员组成。
              </p>
              <strong>
                <p
                  class="p2"
                  style="
                    margin-top: 30px;
                    padding-left: 5.8rem;
                    text-indent: -5.8rem;
                    line-height: 24px;
                  "
                >
                  线上服务入口：WT8/WT9软件界面的右上角菜单【帮助】一>在线客服<br />WT10/wh11软件界面的右上角菜单<img src="../assets/img/sanh.png" alt=""/>
                  一>在线客服
                </p>
              </strong>
            </div>
            <div class="box_img">
              <img src="../assets/img/lunt.png" alt="" />
            </div>
          </div>

          <div class="mt" style="margin-top: 30px">
            <span class="modeNm">渠道二：电话服务</span>
          </div>
          <div class="model_box">
            <div class="box_text">
              <p class="text_indent">
                呼叫中心配备一支金融工程师团队，接听用户电话，解决用户问题。
              </p>
              <p class="text_indent">
                金融工程师团队成员，由具有金融类本科学历、持有期货/证券从业资格证的专业人员组成。
              </p>

              <p class="p2" style="margin-top: 30px">
                400-811-3366<span style="font-weight: normal"
                  >（工作日8:00～18:00）</span
                >
              </p>
            </div>
            <div class="box_img">
              <img src="../assets/img/mpic2.png" alt="" />
            </div>
          </div>

          <!-- <div class="mt" style="margin-top: 30px">
            <span class="modeNm">渠道三：与专属金融工程师在线交流</span>
          </div>
          <div class="model_box">
            <div class="box_text">
              <p class="text_indent">
                文文通除具有一般IM软件的基本功能外，还提供页面、指标、模型的传送功能。
              </p>
              <p class="text_indent">
                购买T8、T9、T10的客户可获得文文通服务，与高级金融工程师对话，为您在产品使用过程中遇到的问题、出现的疑虑提供适合的解决方案。
              </p>
              <p class="text_indent">
                金融工程师团队成员，由具有金融类本科/研究生学历、持有证券期货从业资格证的专业人员组成。
              </p>
              <strong>
                <p
                  class="p2"
                  style="
                    margin-top: 30px;
                    padding-left: 113px;
                    text-indent: -113px;
                    line-height: 24px;
                  "
                >
                  调用文文通方法：T8、T9软件界面的右上角菜单【帮助】一>登录文文通Vip服务软件<br />
                  T10软件界面的右上角菜单<img
                    src="../assets/img/sanh.png"
                    alt=""
                  />
                  一>登录文文通Vip服务软件
                </p>
              </strong>
            </div>
            <div class="box_img">
              <img src="../assets/img/mpic3.png" alt="" />
            </div>
          </div> -->
        </div>
        <div v-if="active_index == 1">
          <div class="article">
            <div>
              <div class="ar_tit">与专属金融工程师在线交流</div>
              <div class="model_box" style="margin-top: 8px">
                <div class="box_text">
                  <p class="text_indent">
                    文文通除具有一般IM软件的基本功能外，还提供页面、指标、模型的传送功能。
                  </p>
                  <p class="text_indent">
                    购买WT8/WT9/WT10-10C/wh11功能的客户可获得文文通服务，与高级金融工程师对话，为您在产品使用过程中遇到的问题、出现的疑虑提供适合的解决方案。
                  </p>
                  <p class="text_indent">
                    金融工程师团队成员，由具有金融类本科/研究生学历、持有证券期货从业资格证的专业人员组成。
                  </p>
                  <p class="text_indent">
                    金触工程师服务时间:工作日8:00~17:00，期间午休11:30~13:00。
                  </p>
                  <strong>
                    <p
                      class="p2"
                      style="
                        padding-left: 121px;
                        text-indent: -120px;
                        line-height: 24px;
                        margin-top: 30px;
                      "
                    >
                      调用文文通方法：WT8/WT9软件界面的右上角菜单【帮助】一>登录文文通Vip服务软件<br />
                      WT10/wh11软件界面的右上角菜单<img src="../assets/img/sanh.png" alt=""/> 一>登录文文通Vip服务软件
                    </p>
                  </strong>
                </div>
              </div>
            </div>
            <!-- <div class="look">
              <div class="uPic"></div>
              <div class="lookInp">
                <div class="kuang">
                  <input
                    type="text"
                    class="myInp"
                    placeholder="输入授权账号，找到您的专属金融工程师"
                    v-model="input"
                  />
                </div>
                <div class="lookBtn" @click="search"><i></i></div>
              </div>
            </div> -->
          </div>
          <div class="post_info" v-if="flag == 1">
            <div class="error_tips">请输入正确授权账号!</div>
            <div
              style="
                line-height: 24px;
                color: #505050;
                font-size: 15px;
                width: 680px;
                margin: 0 auto;
                padding-top: 20px;
              "
            >
              <p>
                咨询软件使用方法、反馈软件的问题或对软件功能有什么建议，请按照<a
                  @click="goto()"
                  style="
                    color: #5086f5;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                  >线上/电话服务</a
                >方式寻求帮助。
              </p>
            </div>
          </div>
          <div class="out" v-if="flag == 2">
            <div class="someOther">
              <div class="content1">
                <div
                  class="conTitle"
                  style="font-size: 18px; color: rgb(186, 143, 108)"
                >
                  专属金融工程师
                </div>
                <div class="detailOut">
                  <div class="conDetail">
                    <div class="photo">
                      <img
                        class="pimg"
                        :src="'https://wt9.wenhua.com.cn/' + data.avatar"
                      />
                    </div>
                    <div class="nPro">
                      <div class="pname">
                        {{ data.showname
                        }}<span style="margin-left: 30px; font-size: 16px"
                          >{{ data.major == "" ? "" : "毕业专业" }}
                          {{ data.major }}
                          <span style="font-weight: normal">{{
                            data.major == "" ? "" : "|"
                          }}</span>
                          入职{{ data.workingYears }}年</span
                        >
                      </div>
                      <div class="talk">
                        <i></i><span>{{ data.sign }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      tab_arr: [
        {
          name: "线上/电话服务",
          index: 0,
        },
        {
          name: "Vip服务通道",
          index: 1,
        },
      ],
      active_index: 0,
      input: "",
      flag: 0,
      data: {},
    };
  },
  methods: {
    changeTab(index) {
      this.active_index = index;
    },
    goto() {
      var url = this.$router.resolve({ path: "/Service?idx=0" });
      window.open(url.href, "_blank");
    },
    search() {
      axios
        .get(
          "https://Wt9.wenhua.com.cn/api/ServiceApi/GetService?id=" + this.input
        )
        .then((res) => {
          // console.log(res)
          if (res.data.status) {
            this.flag = 2;
            this.data = res.data.data;
          } else {
            this.flag = 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    "$route.query.idx": {
      handler: function (newd) {
        this.changeTab(newd);
      },
    },
  },
  created() {
    console.log(this.$route.query.idx, 1);
    this.changeTab(this.$route.query.idx);
  },
};
</script>
<style scoped>
.container {
  padding-top: 50px;
  height: 100%;
  min-height: 400px;
  background: url(../assets/img/bgone.jpg)no-repeat top,url(../assets/img/bgonepp.jpg)repeat-y center top 900px;
  text-align: left;
}
.tab {
  width: 144px;
  z-index: 666;
}
.tab_title {
  font-size: 20px;
  font-weight: 570;
  line-height: 40px;
  padding-left: 8px;
  color: #323232;
  margin-top: 25px;
}
.underline {
  width: 30px;
  height: 2px;
  background: #323232;
  margin-left: 8px;
}
.tab ul {
  margin-top: 20px;
}
.tab ul li {
  color: #999da6;
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  width: 127px;
  padding-left: 12px;
  background: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 0 50px 50px 0;
  padding-right: 18px;
}
.tab ul li:hover{
  color: #3b78f7;
}
.tab ul li.active {
  color: #3b78f7;
  background-image: linear-gradient(90deg,#f9feff,#e9f6ff,#dfebf7);
  background-image: -moz-linear-gradient(90deg,#f9feff,#e9f6ff,#dfebf7);
  box-shadow: #d1d6e5 1px 1px 5px 2px;
}
.content {
  color: rgb(132, 133, 135);
  border-radius: 10px  10px 0 0;
  width: 100%;
  height: 100%;
  min-height: 600px;
  /* margin-left: 40px; */
  background: #fff;
  box-shadow: -1px 0px 3px 0px rgba(209, 222, 238, 0.9);
  padding: 40px 40px 40px 40px;
  font-size: 17px;
}
.mt {
  background: rgb(224, 237, 254);
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}
.modeNm {
  padding-left: 12px;
  background-image: linear-gradient(
    to right,
    rgb(55, 146, 239),
    rgb(12, 184, 250)
  );
  height: 40px;
  line-height: 40px;
  display: inline-block;
  color: #fff;
}
.modeNm::after {
  content: "";
  display: inline-block;
  height: 40px;
  width: 16px;
  border-radius: 0% 100% 91% 9% / 100% 0% 100% 0%;
  position: absolute;
  background: rgb(12, 184, 250);
}
.text_indent {
  text-indent: 2em;
}
.model_box {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.model_box p {
  font-size: 15px;
  padding: 6px 0;
  color: rgb(132, 133, 135);
}
.box_text {
  /* width: 75%; */
}
.box_img {
  display: flex;
  margin-right: 25px;
}
.box_img img {
  align-self: center;
}
.p2 {
  color: rgb(118, 137, 163) !important;
}

.article {
  color: #838383;
  font-size: 15px;
}
.article p {
  /* line-height: 28px; */
}
.ar_tit {
  /* border-left: 4px solid #336ef0;
  padding-left: 6px; */
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  font-weight: bold;
}
.look {
  width: 550px;
  margin: 60px auto;
  overflow: hidden;
  margin-bottom: 0px;
}

.uPic {
  background: url(../assets/img/wenhaol.png) no-repeat;
  background-position-y: 30px;
  width: 120px;
  height: 156px;
  float: left;
  /* margin-right: 20px; */
}

.lookInp {
  width: 380px;
  height: 44px;
  float: left;
  margin-top: 55px;
  overflow: hidden;
}

.kuang {
  width: 295px;
  height: 40px;
  border: 2px solid #6189c4;
  border-right: none;
  overflow: hidden;
  float: left;
}

.myInp {
  width: 93%;
  height: 100%;
  border: 0;
  outline: none;
  padding: 0 10px;
  font-size: 15px;
  color: #8d8d8d;
  *line-height: 40px;
  *height: 40px;
}
.myInp::-webkit-input-placeholder {
  color: #bdbdbd;
}
.myInp::-moz-placeholder {
  color: #bdbdbd;
}
.myInp:-ms-input-placeholder {
  color: #bdbdbd;
}
.myInp:-moz-placeholder {
  color: #bdbdbd;
}
.lookBtn {
  width: 65px;
  height: 44px;
  float: left;
  background: #6189c4;
  cursor: pointer;
}

.lookBtn i {
  display: block;
  background: url(../assets/img/lookbtn.jpg) no-repeat;
  width: 28px;
  height: 28px;
  margin: 8px 0 0 18px;
}
.out {
  width: 100%;
  margin-bottom: 30px;
  /* display: none; */
}

.conTitle,
.wcTitle {
  font-size: 24px;
  color: #3a84fd;
  font-weight: bold;
  margin-bottom: 40px;
}

.detailOut {
  width: 100%;
  position: relative;
}

.conDetail {
  padding-left: 10px;
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
}

.photo,
.nPro,
.pEmail {
  float: left;
  margin-right: 5px;
}

.photo {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  overflow: hidden;
}

.photo img {
  width: 100%;
  height: 100%;
}

.nPro {
  /* width: 170px; */
}
.nPro .talk {
  margin-top: 10px;
}
.talk span {
  background: #f7eadc;
  color: #8d7c7c;
  height: 29px;
  line-height: 29px;
  vertical-align: middle;
  display: inline-block;
  padding-right: 8px;
  border-radius: 0 7px 7px 0;
}
.talk i {
  width: 14px;
  height: 29px;
  vertical-align: middle;
  display: inline-block;
  background: url(../assets/img/duihua.png) no-repeat;
}
.pname,
.ppro {
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
}

.pEmail i {
  display: block;
  float: left;
  width: 29px;
  height: 28px;
  margin: 5px 12px 5px 0;
}

.phone,
.email {
  overflow: hidden;
  height: 35px;
  line-height: 38px;
}

.phone i {
  background: url(../assets/img/uphone.jpg) no-repeat;
}

.email i {
  background: url(../assets/img/email.jpg) no-repeat;
}

.pEmail span {
  font-size: 16px;
  font-weight: bold;
}

.compDet {
  width: 100%;
  background: #d3d3d3;
  padding: 15px 0;
}

.cDetail {
  margin-left: 98px;
}

.cDetail p {
  font-size: 15px;
  margin: 5px 0;
}

.cDetail .comName {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 10px;
}

.scanCode {
  position: absolute;
  right: 30px;
  top: 0;
  width: 174px;
  height: 210px;
  overflow: hidden;
}

.scPic {
  width: 166px;
  height: 166px;
  border: 4px solid #3a84ff;
  border-radius: 5px;
}

.scPic img {
  width: 100%;
  height: 100%;
}

.scTxt {
  width: 100%;
  text-align: center;
  font-size: 17px;
  margin-top: 8px;
  font-weight: 700;
}
.error_tips {
  color: rgb(131, 87, 88);
  font-size: 15px;
  width: 680px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px dashed rgb(202, 202, 202);
  margin: 0 auto;
}
.content1 {
  width: 600px;
  margin: 0 auto;
  background: rgb(255, 252, 247);
  padding: 20px 40px;
}
.router-link-active {
  border: none;
}
</style>