<template>
<div style="display:inline-block;cursor: pointer;vertical-align:middle">
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <g>
        <!-- <path d="m4.65884,5.78352l3.125,-4l3.125,4l-6.25,0z"  :fill="active?'#fff':'#4f6fb8'"/>
        <rect height="10" width="2" y="6" x="7"  :fill="active?'#fff':'#4f6fb8'"/> -->
        <path  d="m9.74998,3.14284l4.28571,5.42857l-8.57143,0l4.28572,-5.42857z" :fill="!order&&active?'#206aff':'#999999'"/>
        <path transform="rotate(180 9.92855 13.7142)" id="svg_3" d="m9.92855,10.99996l4.28571,5.42857l-8.57143,0l4.28572,-5.42857z" :fill="order&&active?'#206aff':'#999999'"/>
        </g>
  </svg>
</div>
</template>

<script>
export default {
    props:{active:{
        default:false,
        type:Boolean,
    },
    order:{
        default:false,
        type:Boolean,
    },
    }
}
</script>

<style>

</style>