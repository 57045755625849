<template>
    <div class="wrap">
        <!-- <div class="title">日内统计数据应用案例</div>
        <div class="titleTime">2024-05-21</div>
        <div class="line"></div> -->

        <div class="paragraph">
            WT10新增的另类数据-日内统计数据，是以高频分钟线数据挖掘的日内高信息增益数据。
        </div>
        <div class="first">一、策略构建原理</div>
        <div class="paragraph">
            根据日内增益信息挖掘可能蓄力待涨的股票，并用技术分析来捕捉趋势机会，获得交易收益。
        </div>
        <div class="first">二、选择因子组合</div>
        <div class="paragraph">
            1、自编“日内放量上涨收益率（5日）”因子：使用日内统计数据函数 get_intraday_statistics 构建因子，该因子可描述股票的上涨蓄力状态。该因子代码如下：
        </div>
        <div class="img_center">
            <BaseBigimg :src="require('./img/图片1.png')"></BaseBigimg>
        </div>
        <div class="paragraph">
            2、MA均线买入条件（量价类-技术指标）：描述股票的多头趋势。
        </div>
        <div class="paragraph">
            由于MA均线买入条件因子值为（1,0），因子合成方式采用“排序相加”。
        </div>
        <div class="img_center">
            <BaseBigimg :src="require('./img/图片2.png')"></BaseBigimg>
        </div>
        <div class="paragraph">
            <!-- 选用日内放量上涨收益率（5日）因子，不采取优化手段，直接回测得： -->
            策略不采取优化手段，直接回测得：
        </div>
        <div class="img_center">
            <BaseBigimg :src="require('./img/图片3.png')"></BaseBigimg>
        </div>
        <div class="paragraph">
            即该量化选股策略今年年初至今的年化收益为42.85%、夏普比率2.97，最大回撤为5.00%。
        </div>
        <div class="paragraph">
            如果将持仓池数量缩小为3只，回测可得：
        </div>
        <div class="img_center">
            <BaseBigimg :src="require('./img/图片4.png')"></BaseBigimg>
        </div>
        <div class="paragraph">
            即该量化选股策略今年年初至今的年化收益为52.02%、夏普比率3.21，最大回撤为6.08%，表现堪称优异。
        </div>

        <div class="first">三、结论</div>


        <!-- 表格 -->
        <div style="width:100%; height: 140px;">
            <div class="table table1">
                <div class="col">
                    <div class="table_head border_left">因子组合</div>
                    <div class="td1 td_com">
                        日内放量上涨收益率（5日）<br>MA均线买入条件
                    </div>
                </div>
                <div class="col1">
                    <div class="table_head">年化收益</div>
                    <div class="td">42.85%</div>
                    <div class="td">52.02%</div>
                </div>
                <div class="col1">
                    <div class="table_head">夏普比率</div>
                    <div class="td">2.97</div>
                    <div class="td">3.21</div>
                </div>
                <div class="col1">
                    <div class="table_head">最大回撤</div>
                    <div class="td">5.00%</div>
                    <div class="td">6.08%</div>
                </div>
                <div class="col1">
                    <div class="table_head">持仓池数量</div>
                    <div class="td">10</div>
                    <div class="td">3</div>
                </div>
            </div>
        </div>

        <div class="paragraph">
            WT10的日内统计类因子，为用户及时提供了日内放量待涨增益信息，辅之以技术指标因子可以挖掘后续上涨空间较大的股票或组合，帮助用户提前占据领先地位、获取更高收益。
        </div>

        <div style="height:20px"></div>
         <!-- <div class="symbolarea">
            <div class="symbol"></div>
            <span class="symboltext">免责说明</span>
        </div> -->
        <note></note>
        <div style="height:25px"></div>
    </div>
</template>
<script>
    import note from '../note.vue'
    export default {
        components:{
            note
        }
    };
</script>
<style scoped src="./style.css"></style>
<style scoped src="../common.css"></style>