import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import 'default-passive-events'
import BaseBigimg from './components/BaseBigimg'
Vue.config.productionTip = false
Vue.component('BaseBigimg', BaseBigimg)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')