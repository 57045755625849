import { render, staticRenderFns } from "./main.vue?vue&type=template&id=51e9fe62&scoped=true&"
import script from "./main.vue?vue&type=script&lang=js&"
export * from "./main.vue?vue&type=script&lang=js&"
import style0 from "./style.css?vue&type=style&index=0&id=51e9fe62&prod&scoped=true&lang=css&"
import style1 from "../common.css?vue&type=style&index=1&id=51e9fe62&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e9fe62",
  null
  
)

export default component.exports