<template>
  <div class="container">
    <div style="width: 1260px; margin: 0 auto; display: flex; height: 100%">
      <div class="tab">
        <div class="tab_title">应用案例</div>
        <!-- <div class="underline"></div> -->
        <ul>
          <li v-for="(item,index) in exampleList" :key="index" class="tit-left-item" :class="{active:index==exampleid}" @click="chooseexample(item,index)">
            <span>{{item.name}}</span><span v-if="index==exampleid" style="float:right">></span>
          </li>
        </ul>
      </div>
      <div class="content">
        <div class="con-text">
          <div style="box-shadow: 0px 2px 3px 0px rgba(209, 222, 238, 0.9);">
            <div class="text-title">
              <div class="txt-title">
              <div v-for="(item,index) in exampleSecList" :key="index" class="txt-left-item" @click="choosetag(item,index)" :class="{active:item.active}">{{item.name}}</div>
            </div>
      
            </div>
          </div>
          <div class="text-txt">
            <component :is="currentComponent" @change="change"></component>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="loading" v-if="showloading">
      <img src="../assets/img/loading.gif" alt="" style="position:absolute;top:42%;left:50%;">
    </div> -->
    <!-- <BaseHover :message="message" v-show="!!message" class="basehover" :style="{left:left+'px',top:top+'px'}"></BaseHover>
    <BaseAlert @close="close" v-if="showalert"></BaseAlert> -->
  </div>
</template>
<script>
import axios from "axios";
import Svgg from "../components/Svgg.vue"
import Basecharts from "../components/Basecharts.vue"
import BaseHover from "../components/BaseHover.vue"
import BaseAlert from "../components/BaseAlert.vue"
import example0 from "../components/Example/example0"
import example1 from "../components/Example/example1"
import example2 from "../components/Example/example2"
import example3 from "../components/Example/example3"
import example4 from "../components/Example/example4"
import example5 from "../components/Example/example5"
import example6 from "../components/Example/example6"
import example7 from "../components/Example/example7"
export default {
components:{
  Basecharts,BaseHover,BaseAlert,Svgg,example0,example1,example2,example3,example4,example5,example6,example7
},
  watch: {
    // "currentComponent": {
    //   handler: function (newd) {
    //     console.log(newd)
    //     console.log(document.documentElement.getElementsByClassName('img_center')[0])
    //   },
    //   deep:true,
    //   immediate:true,
    // },
     "$route.query.example": {
      handler: function (newd) {
        let index1,index2
        this.exampleList.forEach((el,idx)=>{
           let i = el.list.findIndex((el2)=>{
             return  el2.example==newd         
            })
            if(i>-1){
                index1 = idx
                index2 = i
            }
        })
        this.chooseexample(this.exampleList[index1],index1,index2)
      },
      deep:true,
      immediate:true,
    },
  },
created () {
    console.log(this.$route.query.example)
    this.chooseexample(this.exampleList[0],0)
    if(!!this.$route.query.example){
        let index1,index2
        this.exampleList.forEach((el,idx)=>{
           let i = el.list.findIndex((el2)=>{
             return  el2.example==this.$route.query.example          
            })
            if(i>-1){
                index1 = idx
                index2 = i
            }
        })
        this.chooseexample(this.exampleList[index1],index1,index2)
    }
},
data(){
  return{
    exampleid:0,
    exampleList:[
    {
        name:'市场中性',
        list:[{
            name:'运用股指期货对冲，构建市场中性策略',
            example:'example1',
        }]
    },
    {
        name:'指数增强',
        list:[{
            name:'中证500指数增强策略案例',
            example:'example3',
        },{
            name:'沪深300指数增强策略案例',
            example:'example2',
        },]
    },
    {
        name:'行业基本面量化',
        list:[{
            name:'必需消费品行业基本面量化策略',
            example:'example4',
        }]
    },
    {
        name:'另类数据应用',
        list:[{
            name:'事件驱动因子应用案例',
            example:'example6',
        },
        {
            name:'市场系数因子应用案例',
            example:'example5',
        },
        {
            name:'日内统计数据应用案例',
            example:'example7',
        }]
    },
    {
        name:'可转债策略',
        list:[{
            name:'可转债多因子之双低策略案例',
            example:'example0',
        }]
    },],
    exampleSecList:[],
    currentComponent:'example1',

  }
},
methods:{
    chooseexample(item,index,index2){
        this.exampleid=index
        this.exampleSecList=item.list
        this.choosetag(this.exampleSecList[!!index2?index2:0],0)
    },
    choosetag(item,index){
        this.exampleSecList.forEach((el)=>{
            el.active=false
        })
        item.active=true
        this.currentComponent=item.example

    },
    change(example){
      window.scrollTo(0,0)
       let index1,index2
        this.exampleList.forEach((el,idx)=>{
           let i = el.list.findIndex((el2)=>{
             return  el2.example==example          
            })
            if(i>-1){
                index1 = idx
                index2 = i
            }
        })
        this.chooseexample(this.exampleList[index1],index1,index2)
    }
}
};
</script>
<style scoped>
.trans{
  transform: rotate(180deg);
}
.container {
  padding-top: 50px;
  height: 100%;
  min-height: 400px;
  background: url(../assets/img/bgone.jpg)no-repeat top,url(../assets/img/bgonepp.jpg)repeat-y center top 900px;
  text-align: left;
}
.loading{
  position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
}
.content {
  color: rgb(132, 133, 135);
  border-radius: 10px  10px 0 0;
  width: 100%;
  height: 100%;
  min-height: 600px;
  /* margin-left: 40px; */
  background: #fff;
  box-shadow: -1px 0px 3px 0px rgba(209, 222, 238, 0.9);
  padding-top: 23px;
  font-size: 17px;
}
.tab {
  width: 144px;
}
.tab_title {
  font-size: 20px;
  font-weight: 570;
  line-height: 40px;
  padding-left: 8px;
  color: #323232;
  margin-top: 25px;
}
.underline {
  width: 30px;
  height: 2px;
  background: #323232;
  margin-left: 8px;
}
.tab ul {
  margin-top: 20px;
}
.tab ul li {
  color: #999da6;
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  width: 127px;
  padding-left: 12px;
  background: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 0 50px 50px 0;
  padding-right: 18px;
}
.tab ul li:hover{
  color: #3b78f7;
}
.tab ul li.active {
  color: #3b78f7;
  background-image: linear-gradient(90deg,#f9feff,#e9f6ff,#dfebf7);
  background-image: -moz-linear-gradient(90deg,#f9feff,#e9f6ff,#dfebf7);
  box-shadow: #d1d6e5 1px 1px 5px 2px;
}
.text-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* width: 1096px; */
  margin: 0 60px;
}
.text-txt{
  padding-top: 5px;
  font-size: 15px;
  min-height: 600px;
  background: #f6f9fe;
  margin-top: 3px;
  padding-bottom: 40px;
}
.tit-left{
  display: flex;
  flex-direction: row;
}
.right-tip{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 1005px;
  margin: 0 auto;
  cursor: pointer;
}
.right-tip div{
  padding: 2px 4px;
}
/* .right-tip div.active{
  color: white;
  background: #4b63f7;
} */
.tit-left-item{
  cursor: pointer;
  /* padding: 6px 18px; */
  position: relative;
  color: #181818;
}
/* .tit-left-item.active{
  color: #4b63f7;
  border-bottom: 3px solid #4b63f7;
} */
.txt-left-item{
  cursor: pointer;
  margin:0  18px;
  padding: 3px 0px;
  font-size: 16px;
}
  .txt-left-item:hover{
    color: #536b99;
  }
.txt-left-item.active{
  color: #5268cd;
  border-bottom: 3px solid #5268cd;
  font-weight: bold;
}
.txt-img{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 1029px;
  margin: 0 auto;
  margin-top: 5px;
}
.base-ech{
  margin: 0px 12px;
  margin-bottom: 26px;
}
.txt-title{
  display: flex;
  flex-direction: row;
  /* margin-left: 50px; */
}
.txt-table{
  margin-top: 5px;
}



</style>