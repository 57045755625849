<template>
    <div class="wrap">
        <!-- <div class="title">事件驱动因子应用案例</div>
        <div class="titleTime">2024-05-10</div>
        <div class="line"></div> -->

        <div class="paragraph">
            日前，WT10新增了事件驱动另类数据因子，涵盖政策类、技术类、社会类的大事件。
        </div>
        <div class="paragraph">
            以此另类因子为基础，构建基于事件概念基本面、主力行为观测的量化选股策略。
        </div>
        <div class="first">一、策略构建原理</div>
        <div class="paragraph">深度精选市场事件，并在其关联股票中挖掘主力资金流入、后续上涨空间较大的个股或组合。
        </div>
        <div class="first">二、选择因子组合</div>
        <div class="paragraph">1、社会类事件驱动因子（事件驱动类）：描述股票关联重要事件、被关注概率较大；
        </div>
        <div class="paragraph">2、20日换手率平均（量价类-换手率因子）：描述股票持仓者惜筹惜售态度较一致；
        </div>
        <div class="paragraph">3、十大股东持股比例（持股结构类）：描述股东的筹码集中度上升和未来走势强度；
        </div>
        <div class="paragraph">
            由于社会类事件驱动因子值为（-1,0,1），因子合成方式采用“排序相加”。
        </div>
        <div class="img_center">
            <BaseBigimg :src="require('./img/图片1.png')"></BaseBigimg>
        </div>
        <div class="paragraph">策略不采取优化手段，直接回测得：
        </div>
        <div class="img_center">
            <BaseBigimg :src="require('./img/图片2.png')"></BaseBigimg>
        </div>
        <div class="paragraph">即该量化选股策略年化收益25.73%、夏普比率1.27，最大回撤为10.11%。
        </div>
        <div class="paragraph">如果将持仓池数量缩小为3只，回测可得：
        </div>
        <div class="img_center">
            <BaseBigimg :src="require('./img/图片3.png')"></BaseBigimg>
        </div>
        <div class="paragraph">即该量化选股策略年化收益24.32%、夏普比率1.04，最大回撤为19.53%，表现仍然优异。
        </div>

        <div class="first">三、结论</div>


        <!-- 表格 -->
        <div style="width:100%; height: 140px;">
            <div class="table table1">
                <div class="col">
                    <div class="table_head border_left">因子组合</div>
                    <div class="td1 td_com">社会类事件驱动因子<br>20日换手率平均<br>十大股东持股比例</div>
                </div>
                <div class="col1">
                    <div class="table_head">年化收益</div>
                    <div class="td">25.73%</div>
                    <div class="td">24.32%</div>
                </div>
                <div class="col1">
                    <div class="table_head">夏普比率</div>
                    <div class="td">1.27</div>
                    <div class="td">1.04</div>
                </div>
                <div class="col1">
                    <div class="table_head">最大回撤</div>
                    <div class="td">10.11%</div>
                    <div class="td">19.53%</div>
                </div>
                <div class="col1">
                    <div class="table_head">持仓池数量</div>
                    <div class="td">10</div>
                    <div class="td">3</div>
                </div>
            </div>
        </div>

        <div class="paragraph">
            综上可见，WT10的另类因子-社会类事件驱动因子，为用户及时提供了精准的事件概念基本面信号捕捉能力，再结合资金、筹码、投资者意愿的常规因子辅助，可以成功的找到蓄势待发、即将强势上涨的股票，帮助用户提前占据领先地位、获取更高收益。
        </div>

        <div style="height:20px"></div>
         <!-- <div class="symbolarea">
            <div class="symbol"></div>
            <span class="symboltext">免责说明</span>
        </div> -->
        <note></note>
        <div style="height:25px"></div>
    </div>
</template>
<script>
    import note from '../note.vue'
    export default {
        components:{
            note
        }
    };
</script>
<style scoped src="./style.css"></style>
<style scoped src="../common.css"></style>