<template>
  <div ref="rt" :class="{ height2: $route.name == 'About' }">
    <div class="xg-header" :class="{header2:$route.name == 'News'}">
      <div class="header-title">
        <div class="header-logo" @click="$router.push({path:'/home'})">
          <img src="./assets/img/logo_XG.png" alt="" />
          <!-- <div style="margin-left:6px">星格量化投资技术研究所</div> -->
        </div>
        <div class="header-router" v-if="$route.name!='News'">
          <div class="rou">
            <router-link to="/home" class="fit">首页</router-link>
          </div>
          <div class="rous" style="position: relative" ref="rous">
            <div class="jiaa fit">软件产品</div>
            <div class="listpro" style="cursor: pointer">
              <div class="listpro_li">
                <a href="https://wt8.wenhua.com.cn" target="_blank"
                  >WT8 - 麦语言量化交易软件</a
                >
              </div>
              <div class="listpro_li">
                <a href="https://wt9.wenhua.com.cn" target="_blank"
                  >WT9 - 宽语言量化交易软件</a
                >
              </div>
              <div class="listpro_li">
                <a href="https://wt10.wenhua.com.cn" target="_blank"
                  >WT10 - 多因子量化选股软件</a
                >
              </div>
              <div class="listpro_li">
                <a href="https://wh11.wenhua.com.cn" target="_blank"
                  >wh11 - 星格量化投研终端</a
                >
              </div>
            </div>
          </div>
          <!-- <div class="rou">
            <router-link to="/strategy" class="fit">策略广场</router-link>
            <div class="listpro rtnone list4" style="cursor: pointer">
              <div>
                <router-link to="/strategy?type=0">股票策略</router-link>
              </div>
              <div>
                <router-link to="/strategy?type=1">行业策略</router-link>
              </div>
              <div>
                <router-link to="/strategy?type=2">商品策略</router-link>
              </div>
              <div>
                <router-link to="/strategy?type=3">可转债策略</router-link>
              </div>
            </div>
          </div> -->
          <div class="rou">
            <router-link to="/Example" class="fit">应用案例</router-link>
            <!-- <div class="listpro rtnone list4" style="cursor: pointer">
              <div v-for="(item,index) in exampleList" :key="index" class="router-pot listpro_li">
                <router-link :to="'/Example?example='+item.list[0].example">{{item.name}}</router-link>
                <div class="pot">
                  <div class="seclist"> 
                    <div v-for="(item1,index1) in item.list" :key="index1" class="listpro_li listpro_li2">
                      <router-link :to="'/Example?example='+item1.example">{{item1.name}}</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <!-- <div class="rou">
            <router-link to="/Example" class="fit">应用案例</router-link>
          </div> -->
          <div class="rou">
            <router-link to="/Service?idx=0" class="fit">服务</router-link>
            <div class="listpro rtnone" style="cursor: pointer">
              <div class="listpro_li">
                <router-link to="/Service?idx=0">线上/电话服务</router-link>
              </div>
              <div class="listpro_li">
                <router-link to="/Service?idx=1">Vip服务通道</router-link>
              </div>
            </div>
          </div>
          <div class="rou">
            <router-link to="/About" class="fit">关于</router-link>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <div class="xg-footer" :class="{ footer2: $route.name == 'About' }">
      ©版权所有 &nbsp;&nbsp;&nbsp;&nbsp;星格量化投资技术研究所(苏州)有限公司
      &nbsp;&nbsp;&nbsp;&nbsp; 
      <!-- ICP备案号:
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >苏ICP备2023013947号</a
      > -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ifzsy: false,
      exampleList:[
    {
        name:'市场中性',
        list:[{
            name:'运用股指期货对冲，构建市场中性策略',
            example:'example1',
        }]
    },
    {
        name:'指数增强',
        list:[{
            name:'中证500指数增强策略案例',
            example:'example3',
        },{
            name:'沪深300指数增强策略案例',
            example:'example2',
        },]
    },
    {
        name:'行业基本面量化',
        list:[{
            name:'必需消费品行业基本面量化策略',
            example:'example4',
        }]
    },
    {
        name:'另类数据应用',
        list:[{
            name:'事件驱动因子应用案例',
            example:'example6',
        },
        {
            name:'市场系数因子应用案例',
            example:'example5',
        },
        {
            name:'日内统计数据应用案例',
            example:'example7',
        }]
    },
    {
        name:'可转债策略',
        list:[{
            name:'可转债多因子之双低策略案例',
            example:'example0',
        }]
    },],
    };
  },
  // watch:{
  //         $route:{
  //         handler(val,oldval){
  //             this.$nextTick(()=>{
  //                 console.log(val,oldval)

  //                 console.log(window.screen.height,this.$refs.rt.getBoundingClientRect().height)
  //                 if(window.screen.height<=this.$refs.rt.getBoundingClientRect().height){
  //                     this.ifzsy=false
  //                 }
  //                 else{
  //                      this.ifzsy=true
  //                 }
  //             })
  //         },
  // }
  // }
  // data(){
  //     return {
  //         active:false,
  //     }
  // },
  // methods:{
  //     showlisu(e){
  //         if(!!this.$refs.rous&&this.$refs.rous.contains(e.target)){
  //             this.active=!this.active
  //         }
  //         else{
  //             this.active=false
  //         }
  //     },
  // },
  created() {
    console.log(this.$route.name);
    window.addEventListener("resize", console.log(23));
  },
  // beforeDestroy(){
  //     window.removeEventListener('click',this.showlisu)
  // }
};
</script>

<style>
.height2 {
  height: 100%;
}
.xg-header {
  height: 75px;
  background: #f6f9fe;
  color: #505050;
  /* filter: drop-shadow(0 2px 4px #f6f9fe); */
  font-size: 16px;
}
  .header2{
    box-shadow: 0 2px 5px #d0d0d0;
    border-bottom: 3px solid #668eca;
    filter: drop-shadow(0 2px 4px #d0d0d0);
    background: white;
  }
.header-title {
  width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.header-logo {
  display: flex;
  font-size: 23px;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
.header-router {
  align-items: center;
  height: 100%;
  display: flex;
}
.header-router .rou {
  margin-left: 32px;
  margin-top: 11px;
  position: relative;
}
.header-router .rous {
  margin-left: 32px;
  cursor: pointer;
  margin-top: 11px;
}
.jiaa {
  text-decoration: none;
  color: #5f6062;
  display: block;
  height: 26px;
  border-bottom: 4px solid transparent;
}
/* .jiaa.active{
    border-bottom: 4px solid;
    -o-border-image: linear-gradient(90deg,#4060ff,#0dc4fd 99%) 2 2 2 2;
    border-image: linear-gradient(90deg,#4060ff,#0dc4fd 99%) 2 2 2 2;
} */
.header-router .rou a {
  text-decoration: none;
  color: #5f6062;
  display: block;
  height: 26px;
  border-bottom: 4px solid transparent;
}
.header-router .rou .fit:hover {
  color: #1342c2;
}
.header-router .rous .fit:hover {
  color: #1342c2;
}
.router-link-active {
  border-bottom: 4px solid;
  -o-border-image: linear-gradient(90deg, #4060ff, #0dc4fd 99%) 2 2 2 2;
  border-image: linear-gradient(90deg, #4060ff, #0dc4fd 99%) 2 2 2 2;
}
.xg-footer {
  background: #2d294a;
  color: #f2f1f7;
  text-align: center;
  height: 80px;
  line-height: 80px;
  font-size: 16px;
}
.xg-footer a {
  color: #f2f1f7;
  text-decoration: none;
}
.listpro {
  position: absolute;
  width: 200px;
  height: 0px;
  top: 46px;
  font-size: 15px;
  margin-top: -5px;
  background: white;
  overflow: hidden;
  transition: height 0.5s;
  -moz-transition: height 0.5s; /* Firefox 4 */
  -webkit-transition: height 0.5s; /* Safari and Chrome */
  -o-transition: height 0.5s; /* Opera */
  border: 1px solid transparent;
}
.rous:hover .listpro {
  height: 160px;
  border: 1px solid #c2c2c2;
  z-index: 999;
}
.rou:hover .listpro {
  height: 80px;
  border: 1px solid #c2c2c2;
  z-index: 999;
}
.list4 {
  width: 140px;
}
.rou:hover .list4{
  height: 200px;
  border: 1px solid #c2c2c2;
  z-index: 999;
  overflow:visible;
}
.listpro .listpro_li {
  height: 40px;
  text-align: left;
  padding-left: 8px;
  line-height: 40px;
}
.listpro div a {
  text-decoration: none;
  color: #5f6062;
}
.listpro div a:visited {
  color: #5f6062;
}
.listpro .listpro_li:hover {
  background: #4597e1;
}
.listpro .listpro_li:hover a {
  color: white;
}
.listpro .listpro_li:hover .listpro_li2 a {
  color: #5f6062;
}
.listpro .listpro_li2:hover a {
  color: white !important;
}
.rtnone .router-link-active {
  border: none;
}
.footer2 {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.router-pot{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.pot{
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid #333;
    height: 0px !important;
    margin-right: 5px;
    position: relative;
    /* position: absolute; */
}
.seclist{
  position: absolute;
  color: #5f6062;
  background: white;
  left: 0px;
  top: -20px;
  display: none;
  z-index: 9999;
}
.pot:hover .seclist{
  display: block;
}
.seclist .listpro_li{
  width: 300px;
}
.list4 .router-link-active{
  border-bottom: 0 !important;
  height: auto !important;
}
</style>