<template>
    <div class="wrap">
        <!-- <div class="title">可转债多因子之双低策略案例</div>
        <div class="titleTime">2024-05-10</div>
        <div class="line"></div> -->

        <div class="paragraph">
            对于可转债，相信大家早已耳熟能详。作为一个债券品种，可转债在1993年就已经上市，但一直到2017年底才真正活跃起来。2022年7月沪深交易所发布了《可转换公司债券交易实施细则》与《可转换公司债券自律监管指引》，主要针对可转债炒作等问题进行了进一步规范，加强了投资者保护，有效推动可转债市场健康发展。受政策影响，可转债市场规模逐步扩大，到2022年底存续只数已达466只，存续规模8084亿元。
        </div>

        <div class="paragraph">
            就可转债投资策略而言，当市场中可转债只数较少的时候，以主观交易为主，基金经理根据对于公司基本面及可转债走势的判断，构建投资组合。当存续只数迅速增多后，基金经理的研究难度急剧增加，量化方法便开始崭露头角，现今已成为主流的可转债策略之一。就量化策略而言，多因子策略是主流，本案例就围绕可转债投资领域中最为经典的“双低”策略展开。
        </div>

        <div class="first">一、双低策略介绍</div>

        <div class="second">1、策略理念</div>
        <div class="paragraph">
            可转债的未来收益同时受债性和股性影响，双低策略的理论基础在于债性和股性的均衡，最初的策略是指买入低价、低转股溢价率的可转债，持有并根据一定条件轮动的策略。具体来看，低绝对价格代表其到期收益率较高，债性较强，正股下跌对转债的压制较小，转债价格下跌幅度有限。低转股溢价率代表其股性较强，当正股价格上涨时，转债内含的转股价值能够有效提升转债的价值，反映到转债价格上可以较好地跟随正股上涨。
        </div>

        <div class="second">2、双低指标计算方式</div>
        <div class="paragraph">
            双低策略的理念非常易懂，但市面上并没有一个公认的计算方式，各家的公式都有差异。通常，双低指标的构建由“债性+股性”指标组成，目前有以下五种常见方式：
        </div>

        <!-- 表格 -->
        <div style="width:100%">
            <div class="table table2">
                <div class="col6">
                    <div class="table_head border_left">序号</div>
                    <div class="td3 td_left">1</div>
                    <div class="td2 td_left">2</div>
                    <div class="td3 td_left">3</div>
                    <div class="td3 td_left">4</div>
                    <div class="td2 td_left">5</div>
                </div>
                <div class="col col1">
                    <div class="table_head">计算公式</div>
                    <div class="td3 td_left">转债价格+转股溢价率(%)*100</div>
                    <div class="td2 td_left">转债价格和转股溢价率(%)分别标准化后等权合成</div>
                    <div class="td3 td_left">(转债价格−纯债价值)+转股溢价率(%)*100</div>
                    <div class="td3 td_left">转债价格+(转债价格−转股价值)</div>
                    <div class="td1 td_left">(转债价格−纯债价值)+(转债价格−转股价值)</div>
                    <div class="td1_bottom td_left">注：若回售价值大于纯债价值，则取回售价值。</div>
                </div>
                <div class="col col2">
                    <div class="table_head">特点</div>
                    <div class="td1">
                        <div class="dot"></div>转债价格用于衡量债性，转股溢价率用于衡量股性
                    </div>
                    <div class="td1">
                        <div class="dot"></div>简单直观，但量纲不统一
                    </div>
                    <div class="td1_bottom">
                        <div class="dot"></div>见于《集思录》
                    </div>
                    <div class="td1">
                        <div class="dot"></div>简单直观，量纲统一
                    </div>
                    <div class="td1_bottom">
                        <div class="dot"></div>见于机构研报
                    </div>
                    <div class="td1">
                        <div class="dot"></div>转债价格与债底之差，用于衡量债性，对于债性的变动更加敏感
                    </div>
                    <div class="td1">
                        <div class="dot"></div>量纲不统一
                    </div>
                    <div class="td1_bottom">
                        <div class="dot"></div>见于机构研报
                    </div>
                    <div class="td1">
                        <div class="dot"></div>转债价格与平价之差，用于衡量股性
                    </div>
                    <div class="td1">
                        <div class="dot"></div>保持了股性和债性指标的经济单位统一
                    </div>
                    <div class="td1_bottom">
                        <div class="dot"></div>见于《宁稳网--老式双低指标》
                    </div>
                    <div class="td1">
                        <div class="dot"></div>保持了股性和债性指标的经济单位统一
                    </div>
                    <div class="td1_bottom">
                        <div class="dot"></div>见于《宁稳网--新式双低指标》
                    </div>
                </div>
            </div>
        </div>

        <div class="paragraph">
            其中，对于第5种方式，由于有的个券是减纯债价值，有的个券是减回售价值，而且有的个券没有回售价值（因为没有回售权），从量化角度看，指标不一致会导致计算结果不可比的问题。考虑到回售价值大于纯债价值情形的占较小，就算在该情形下二者的差异也很小，为了实现方便，在本案例中将方式5改为统一减去纯债价值。
        </div>

        <div class="second">3、双低策略升级版——三低策略</div>
        <div class="paragraph">
            在价格和转股溢价率之外，转债的剩余规模，也会对转债的估值产生较大影响。一般来说，规模较大的转债，不易受资金行为扰动，债性较强，呈现低波动性；规模较小的转债，定价易受资金行为扰动，股性相对活跃，波动率相对较高。
        </div>
        <div class="paragraph">
            三低策略的内核还是双低，强调的是股性与债性平衡，但又延伸出“低规模”这一维度。考虑到剩余规模与双低指标都不是同一个量纲，我们研究的三低策略计算方式为：转债价格、转股溢价率(%)和剩余规模分别由低到高排序，然后对三个排序值等权合成，下文称“方式6”。
        </div>

        <div class="first">二、可转债策略功能介绍及双低策略设置</div>

        <div class="second">1、基本参数</div>
        <div class="paragraph">
            相比市面上现有的可转债量化平台，初始资金设置为WT10独有。设置资金后，在历史回测时完全按照实际可以买卖的转债张数和年息收入计算，使回测报告更接近真实情况。
        </div>

        <div class="img_center">
            <img class="img" src="./img/图片1.png" alt="">
        </div>

        <div class="second">2、选券范围</div>
        <div class="paragraph">
            可转债的投资生命周期特征非常明显，处于无效生命周期的转债投资价值较低。WT10可以事先过滤掉已经公告强赎，及已经满足条件具有较高强赎风险但尚未发布公告的可转债。另外，正股处于ST状态的可转债投资价值降低，历史上停牌的转债无法交易等问题，可同样一并考虑。剔除指标可以进一步锁定投资范围，如上市天数、剩余天数、剩余规模、信用评级等。
        </div>

        <div class="img_center">
            <BaseBigimg :src="require('./img/图片2.png')"></BaseBigimg>
        </div>

        <div class="second">3、添加因子</div>
        <div class="paragraph">
            当前我们已经提供4大类60+可转债因子，囊括了市面上常见的因子和机构最新的因子研究成果，同时在函数列表中，提供了内容丰富、形式整齐的历史数据，可以用来自编因子。以计算方式6为例，添加因子如下：
        </div>

        <div class="img_center">
            <BaseBigimg :src="require('./img/图片3.png')"></BaseBigimg>
        </div>

        <div class="paragraph">
            此外，预处理中的市值中性化、行业中性化常见于股票多因子量化策略，就可转债因子而言，其因子收益同样会受规模和行业的影响，对于受影响较大的因子可以进行中性化处理，以提升因子效果的稳定性。
        </div>

        <div class="second">4、策略优化</div>
        <div class="paragraph">
            持仓组合赋权方面，市面上常见的只有等权，WT10提供了6种赋权优化方式；
        </div>

        <div class="img_center">
            <img class="img" src="./img/图片4.png" alt="">
        </div>

        <div class="paragraph">
            可转债的大盘择时功能，为WT10独有，我们提供了常见的7种择时案例及自定义方式。本文中默认不择时。
        </div>

        <div class="first">三、各计算方式结果汇总</div>

        <div class="paragraph">
            <span class="Bfont">策略基础设置如下：</span>
        </div>
        <div class="paragraph">
            <div class="dot"></div>时间范围：2020.1.2--2023.1.31；
        </div>
        <div class="paragraph">
            <div class="dot"></div>计算频率：每周第1个交易日；
        </div>
        <div class="paragraph">
            <div class="dot"></div>持仓组合优化：等权；
        </div>
        <div class="paragraph">
            <div class="dot"></div>按债券数目，综合评分由高到低前10只。
        </div>

        <!-- 表格 -->
        <div style="width:100%">
            <div class="table table1">
                <div class="col">
                    <div class="table_head border_left">序号</div>
                    <div class="td2_bottom">1</div>
                    <div class="td2_bottom">2</div>
                    <div class="td2_bottom">3</div>
                    <div class="td2_bottom">4</div>
                    <div class="td2_bottom">5</div>
                    <div class="td2_bottom">6</div>
                    <div class="td2_bottom">中证转债指数</div>
                </div>
                <div class="col3">
                    <div class="table_head">年化收益(%)</div>
                    <div class="td">62.38</div>
                    <div class="td">44.41</div>
                    <div class="td">60.27</div>
                    <div class="td">36.41</div>
                    <div class="td">22.36</div>
                    <div class="td">42.35</div>
                    <div class="td">5.22</div>
                </div>
                <div class="col3">
                    <div class="table_head">夏普比率</div>
                    <div class="td">1.81</div>
                    <div class="td">2.37</div>
                    <div class="td">1.75</div>
                    <div class="td">2.12</div>
                    <div class="td">1.34</div>
                    <div class="td">2.22</div>
                    <div class="td">0.12</div>
                </div>
                <div class="col3">
                    <div class="table_head">最大回撤(%)</div>
                    <div class="td">32.73</div>
                    <div class="td">15.31</div>
                    <div class="td">32.32</div>
                    <div class="td">16.67</div>
                    <div class="td">17.25</div>
                    <div class="td">11.14</div>
                    <div class="td">12.17</div>
                </div>
                <div class="col3">
                    <div class="table_head">收益波动率(%)</div>
                    <div class="td">32.34</div>
                    <div class="td">17.05</div>
                    <div class="td">32.08</div>
                    <div class="td">15.28</div>
                    <div class="td">13.69</div>
                    <div class="td">17.31</div>
                    <div class="td">10.27</div>
                </div>
            </div>
        </div>

        <div class="paragraph">
            由上表可知，计算方式1的年化收益是最高的，但是再看看回撤和波动指标会发现策略的风险也不小。如果比较一下其他计算方式的结果，也许您会有不同的发现。此处，只列出部分数据，更详尽的年度收益统计、回撤分析、持仓明细等，您可以到回测报告中详细查阅。
        </div>



        <div style="height:20px"></div>
        <!-- <div class="symbolarea">
            <div class="symbol"></div>
            <span class="symboltext">免责说明</span>
        </div> -->
        <note></note>
        <div style="height:25px"></div>
    </div>
</template>
<script>
    import note from '../note.vue'
    export default {
        components:{
            note
        }
    };
</script>
<style scoped src="./style.css"></style>
<style scoped src="../common.css"></style>
