<template>
<div class="wrap">
    <!-- <div class="title">溢价倍数因子应用案例</div>
    <div class="titleTime">2024-06-17</div>
    <div class="line"></div> -->

    <div class="paragraph">
        市场系数是WT10以其原创市场法估值数据构造的另类因子，可以构建基于估值水平的量化选股策略。
    </div>
    <div class="first">一、策略构建原理</div>
    <div class="paragraph">根据估值水平来精选市场关注度比较高、交易惯性比较强的股票，用技术分析来捕捉趋势机会，获得交易收益。
    </div>
    <div class="first">二、选择因子组合</div>
    <div class="paragraph">1、市场系数（市场估值类）：描述从市场法估值角度衡量的估值水平；
    </div>
    <div class="paragraph">2、BIAS乖离率买入条件（量价类-技术指标）：描述空头力量消耗严重、多头力量复苏；
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片1.png')"></BaseBigimg>
    </div>
    <div class="paragraph">策略不采取优化手段，直接回测得：
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片2.png')"></BaseBigimg>
    </div>
    <div class="first">三、结论</div>
    <div class="paragraph">
        综上可见，WT10的市场系数因子，为用户及时提供了更精准的估值水平信息，挖掘出交易惯性较强的股票，辅之以技术指标因子可以找到有上涨空间的股票，帮助用户提前占据领先地位、获取更高收益。
    </div>

    <div style="height:20px"></div>
     <!-- <div class="symbolarea">
        <div class="symbol"></div>
        <span class="symboltext">免责说明</span>
    </div> -->
    <note></note>
    <div style="height:25px"></div>
</div>
</template>
<script>
    import note from '../note.vue'
    export default {
        components:{
            note
        }
    };
</script>
<style scoped src="../common.css"></style>