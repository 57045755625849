<template>
<div class="wrap">
    <!-- <div class="title">必需消费品行业基本面量化策略</div>
    <div class="titleTime">2024-05-10</div>
    <div class="line"></div> -->

    <div class="paragraph">
        嗨，孜孜不倦的小量量又来啦，往期小量复现了中证500增强策略和沪深300指数增强策略，您可能觉得怎么全是在指数上进行研究，有没有点新鲜的。今天小量就聊一聊近期比较热门的行业基本面量化策略吧。
    </div>
    <div class="first">一、行业基本面量化介绍</div>
    <div class="paragraph">传统的基本面投资者和量化投资者的分析和投资方法有着明显的区别。基本面投资的特点是筛选公司后进行深入研究、少而精。量化投资的特点是挖掘市场规律、更有广度。量化基本面研究是量化投资与基本面投资的有机结合，与传统的多因子选股相比，量化基本面研究更加注重公司经营的本质，它的主要思路是在把握上市公司运营特征和竞争格局的基础上，从基本面角度筛选出行业内具有竞争力的优质公司，并选择合适的时机进行投资。</div>
    <div class="paragraph">然而，不同行业之间的上市公司运营特征、竞争环境均存在显著差异，从基本面角度难以找到一个对全市场所有上市公司都有效的选股逻辑。因此，我们要把模型做细，不是简单地拿全市场选股模型来解决问题，而是要求更加重视基本面的维度来做量化研究，注重针对具有共同经营特征的上市公司的筛选，即构造行业内的多因子选股策略。</div>
    <div class="paragraph">了解了以上内容，您是不是也迫不及待地想要见识一下行业基本面量化策略啦？下面跟着小量的脚步，参考专业券商的金工研报，利用WT10量化功能，快速开启您的行业基本面量化之旅吧！</div>
    <div class="paragraph">本文的参考研报为：《光大证券-行业基本面选股系列报告之一：必需消费品，毛利、周转双轮驱动》。</div>

    <div class="first">二、策略复现</div>
    <div class="second">1、基本参数</div>
    <div class="paragraph">首先交代一下策略回测的基本参数：
    </div>
    <div class="paragraph">时间范围：2018.1.2--2024.5.7
    </div>
    <div class="paragraph">计算频率：每月第一个交易日
    </div>
    <div class="paragraph">收益基准：沪深300
    </div>

    <div class="second">2、选股范围</div>
    <div class="paragraph">（1）选择目标行业作为基础股票池
    </div>
    <div class="paragraph">该研报主要探讨必需消费品行业的选股逻辑，主要包括：食品、医药、服饰、日用品等，选择的必须消费品行业分类如下：
    </div>
    
    
    <!-- 表格 -->
    <div style="width:100%">
    <div class="table0">
        <div class="col col_first1">
            <div class="table_head">申万一级行业分类</div>
            <div class="td">食品饮料</div>
            <div class="td">商贸零售</div>
            <div class="td">纺织服装</div>
            <div class="td">医药生物</div> 
        </div>
        <div class="col">
            <div class="table_head">申万二级行业分类</div>
            <div class="td">饮料制造、食品加工</div>
            <div class="td">一般零售</div>
            <div class="td">服装家纺</div>
            <div class="td">化学制药、中药、生物制品</div> 
        </div>
    </div>
    </div>
    
    <div class="paragraph">由于申万二级行业历史数据里的饮料制造包含白酒股票，但是白酒股票不属于必需消费品行业，所以我们在行情界面新建“我的自选”，将白酒概念股票添加到自选里，通过指定过滤功能，选择该自选，过滤掉白酒概念的股票。
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片1.png')"></BaseBigimg>
    </div>
    <div class="paragraph">（2）运用剔除指标打造精选股票池
    </div>
    <div class="paragraph">该研报认为必需消费品行业的上市公司主要有三个特点：需求受经济周期影响相对较小、费用水平难以降低、渠道为王快速迭代。从基本面角度看，必需消费品行业的费用水平难以有效降低，因此毛利水平是影响公司利润的最重要因素。进一步来看，提高运营效率，实现产品的快速迭代，是提升毛利的重要途径。所以选取了毛利增长和运营效率两个关键指标作为剔除指标，剔除行业内明显没有投资价值的股票。
    </div>
   
    <div class="paragraph">剔除毛利润_TTM同比较小的前33%的股票，并且剔除存货周转率_TTM较小的前50%股票，留下优质股票，下图为最终的选股范围
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片2.png')"></BaseBigimg>
    </div>
    <div class="second">3、添加因子</div>
    <div class="paragraph">确定选股范围后，进一步准备从估值角度选择其中具投资价值的股票。根据研报小量对价值类因子均进行了有效性检验。我们最终选择使用修正股息率因子来进行选股。需要说明的是，我们所选用的修正股息率因子是处理过异常值的，具体算法您可以在系统因子-分析界面右上角查看系统因子源码。
    </div>
    <div class="paragraph">小量只使用了一个因子来进行选股，所以这里不涉及因子相关性分析和综合因子合成。
    </div>
    <div class="second">4、策略优化</div>
    <div class="paragraph">根据研报流程，暂不进行大盘择时、个股择时、中性化处理。
    </div>
    <div class="second">5、回测报告</div>
    <div class="paragraph">在精选股票池内，根据修正股息率因子从大到小排序，选出前三分之一的股票持仓。交易手续费为默认设置，在该步骤可查看策略回测分析报告以及最新持仓列表。
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片3.png')"></BaseBigimg>
    </div>
    <div class="second">6、策略对比</div>
    <div class="paragraph">最后，小量构造四个行业策略生成回测分析报告进行对比（注：策略对比是策略自动运行到最新交易日生成的回测报告对比分析功能）：
    </div>

     <!-- 表格 -->
    <div style="width:100%">
    <div class="table">
        <div class="col1 col_first1">
            <div class="table_head">策略序号</div>
            <div class="td">一</div>
            <div class="td">二</div>
            <div class="td">三</div>
            <div class="td">四</div> 
        </div>
        <div class="col2">
            <div class="table_head">策略目标</div>
            <div class="td">获取必需消费品行业平均收益</div>
            <div class="td">获取必需消费品行业精选股票池平均收益</div>
            <div class="td">获取必需消费品行业内修正股息率因子选股收益</div>
            <div class="td">获取必需消费品行业精选股票池内修正股息率因子选股收益</div> 
        </div>
        <div class="col5">
            <div class="table_head">策略内容</div>
            <div class="td">基础股票池作为全部持仓</div>
            <div class="td">精选股票池作为全部持仓</div>
            <div class="td">基础股票池+修正股息率因子</div>
            <div class="td">精选股票池+修正股息率因子</div> 
        </div>
    </div>
    </div>

    <div class="img_center">
        <BaseBigimg :src="require('./img/图片4.png')"></BaseBigimg>
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片5.png')"></BaseBigimg>
    </div>
    <div class="img_center">
        <BaseBigimg :src="require('./img/图片6.png')"></BaseBigimg>
    </div>
    <div class="paragraph">从上面三个图可以看到，策略二对比策略一，年化收益从2.63%提升到4.14%，夏普比率从-0.06提升到0.01；策略二的收益曲线也是稳定优于策略一，年超额收益的胜率达到了71.43%,说明必需消费品行业精选股票池平均收益是要稳定优于必需消费品行业平均收益。策略四对比策略三，收益统计指标均有提升，说明修正股息率因子在必需消费品行业精选股票池中使用比在基础股票池中能取得更高的收益。策略四对比策略一、二和三，年化收益从2.63%、4.14%和7.62%提升到13.01%，夏普比率从-0.06、0.01和0.17提升到0.41，最大回撤率从34.19%、32.40%和31.88%降低到30.32%，收益曲线明显更优。对比策略一，策略四的年超额收益的胜率达到了100%；对比策略二和策略三，年超额收益的胜率也达到了100%、85.71%。说明针对行业运营特征和竞争格局进行分析，从基本面角度筛选出行业内极具竞争力的优质公司，并选择合适的时机进行投资，是可以获得超额收益的。
    </div>


    <div class="first">三、结论</div>
    <div class="paragraph">经过上述研究和复现，小量可以得出以下结论：
    </div>
    <div class="paragraph">1.WT10可以通过剔除指标构造更精准的选股范围。
    </div>
    <div class="paragraph">2.WT10界面操作式的量化功能，可以助您方便快捷地实现行业基本面量化策略思路并检验回测效果。
    </div>



    <div style="height:20px"></div>
     <!-- <div class="symbolarea">
        <div class="symbol"></div>
        <span class="symboltext">免责说明</span>
    </div> -->
    <note></note>
    <div style="height:25px"></div>
</div>
</template>
<script>
    import note from '../note.vue'
    export default {
        components:{
            note
        }
    };
</script>

<style scoped src="./style.css"></style>
<style scoped src="../common.css"></style>