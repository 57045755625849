<template>
  <div class="container" style="height: calc(100% - 155px)">
    <div style="width: 1260px; margin: 0 auto; display: flex; height: 100%">
      <div class="tab">
        <div class="tab_title">关于</div>
        <!-- <div class="underline"></div> -->
        <ul>
          <!-- <li
            v-for="(item, index) in tab_arr"
            :key="index"
            @click="changeTab(item.index)"
            :class="active_index == item.index ? 'active' : ''"
          >
            <span>{{ item.name }}</span>
          </li> -->
        </ul>
      </div>
      <div class="content" style="height: calc(100% - 90px)">
        <div class="content-txt">
          <!-- <div
            style="
              font-size: 19px;
              color: #666666;
              margin-bottom: 25px;
              font-weight: bold;
            "
          >
            关于
          </div> -->
          <div>
            星格量化投资技术研究所(苏州)有限公司，是上海文华财经资讯股份有限公司的全资子公司，专注于量化软件业务。
          </div>
          <div style="margin-top: 20px">
            >>><a href="https://www.wenhua.com.cn" target="_blank"
              >进入文华财经官网</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // tab_arr: [
      //   {
      //     name: "星格量化研究所",
      //     index: 0,
      //   },
      // ],
      height: 0,
      // active_index: 0,
    };
  },
  methods: {
    changeTab(index) {
      this.active_index = index;
    },
  },
  mounted() {
    this.height = document.body.clientHeight - 201;
  },
};
</script>
<style scoped>
.container {
  padding-top: 50px;
  height: 100%;
  min-height: 400px;
  background: url(../assets/img/bgone.jpg)no-repeat top,url(../assets/img/bgonepp.jpg)repeat-y center top 900px;
  text-align: left;
}
.tab {
  width: 144px;
  visibility: hidden;
}
.tab_title {
  font-size: 17px;
  font-weight: 570;
  line-height: 40px;
  padding-left: 8px;
  color: #323232;
}
.underline {
  width: 30px;
  height: 2px;
  background: #323232;
  margin-left: 8px;
}
.tab ul {
  margin-top: 30px;
}
.tab ul li {
  color: rgb(66, 94, 144);
  height: 40px;
  line-height: 40px;
  font-size: 17px;
  width: 160px;
  padding-left: 8px;
  background: transparent;
  cursor: pointer;
  margin-bottom: 10px;
}
.tab ul li.active {
  background-color: rgb(229, 242, 251);
  box-shadow: #d1d6e5 1px 1px 5px 2px;
}
.content {
  color: rgb(132, 133, 135);
  width: 100%;
  height: 100%;
  min-height: 600px;
  background: #fff;
  box-shadow: -6px 0px 3px 0px rgba(209, 222, 238, 0.9);
  padding: 40px 40px 40px 40px;
  font-size: 16px;
  border-radius: 10px  10px 0 0;
}
.content-txt {
  padding: 0px 30px 40px 30px;
}
.content-txt a {
  color: #5086f5;
}
</style>