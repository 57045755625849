<template>
  <div class="container">
    <div style="width: 1260px; margin: 0 auto; display: flex; height: 100%">
      <div class="tab">
        <div class="tab_title">策略广场</div>
        <!-- <div class="underline"></div> -->
        <ul>
          <li v-for="(item,index) in strategyList" :key="index" class="tit-left-item" :class="{active:index==strategyid}" @click="choosestrategy(index)">
            <span>{{item}}</span><span v-if="index==strategyid" style="float:right">></span>
          </li>
        </ul>
      </div>
      <div class="content">
        <div class="con-text" v-if="!showloading">
          <div style="box-shadow: 0px 2px 3px 0px rgba(209, 222, 238, 0.9);">
            <div class="text-title">
              <div class="txt-title">
              <div v-for="(item,index) in showtag" :key="index" class="txt-left-item" @click="choosetag(item,index)" :class="{active:item.active}">{{item.it}}</div>
            </div>
      
            </div>
          </div>
          <div class="text-txt">
            <div class="tit-right">
                <div class="right-tip">
                  <div @click="changetaimg(false)" :class="{active:!ifshowtable}">
                    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                        <g id="Layer_1">
                          <rect id="svg_1" height="7" width="7" y="0" x="0"  :fill="ifshowtable?'#828282':'#ff6805'"/>
                          <rect id="svg_2" height="7" width="7" y="9" x="0"  :fill="ifshowtable?'#828282':'#ff6805'"/>
                          <rect id="svg_3" height="7" width="7" y="0" x="9"  :fill="ifshowtable?'#828282':'#ff6805'"/>
                          <rect id="svg_4" height="7" width="7" y="9" x="9"  :fill="ifshowtable?'#828282':'#ff6805'"/>
                        </g>
                    </svg>
                  </div>
                  <div @click="changetaimg(true)" :class="{active:ifshowtable}">
                    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                        <g id="Layer_2">
                          <rect id="svg_1" height="4" width="4" y="0" x="0"  :fill="ifshowtable?'#ff6805':'#828282'"/>
                          <rect id="svg_2" height="4" width="4" y="6" x="0"  :fill="ifshowtable?'#ff6805':'#828282'"/>
                          <rect id="svg_3" height="4" width="4" y="12" x="0"  :fill="ifshowtable?'#ff6805':'#828282'"/>
                          <rect id="svg_4" height="4" width="10" y="0" x="6"  :fill="ifshowtable?'#ff6805':'#828282'"/>
                          <rect id="svg_5" height="4" width="10" y="6" x="6"  :fill="ifshowtable?'#ff6805':'#828282'"/>
                          <rect id="svg_6" height="4" width="10" y="12" x="6"  :fill="ifshowtable?'#ff6805':'#828282'"/>
                        </g>
                    </svg>
                  </div>
                </div>
            </div>
            <!-- <div class="txt-title">
              <div v-for="(item,index) in showtag" :key="index" class="txt-left-item" @click="choosetag(item,index)" :class="{active:item.active}">{{item.it}}</div>
            </div> -->
            <div class="txt-img" v-if="!ifshowtable">
              <div v-for="(item,index) in showimglist" :key="index" class="base-ech" @click.stop="al()">
                <Basecharts :ech="item.listInfo"></Basecharts>
              </div>
            </div>
            <div class="txt-table" v-else>
              <div>
                <table style="margin: 0 auto;">
                  <thead>
                    <tr><td style="width:210px;color:#53699a">名称</td>
                    <td v-for="(item,index) in orderlist" :key="index" @click="od(item.ename,index)">
                      {{item.name}}<Svgg :active="orderlist[index].active" :order="order"></Svgg>
                    </td>
                    <!-- <td @click="od('annualProfit')">年化收益<Svgg :active="orderlist[0]" :class="{trans:order&&orderlist[0]}"></Svgg></td>
                    <td @click="od('weekProfit')">近一周收益<Svgg :active="orderlist[1]" :class="{trans:order&&orderlist[0]}"></Svgg></td>
                    <td @click="od('monthProfit')">近一个月收益<Svgg :active="orderlist[2]" :class="{trans:order&&orderlist[0]}"></Svgg></td>
                    <td @click="od('threeYearProfit')">近三年收益<Svgg :active="orderlist[3]" :class="{trans:order&&orderlist[0]}"></Svgg></td>
                    <td @click="od('maxFallBack')">最大回撤<Svgg :active="orderlist[4]" :class="{trans:order&&orderlist[0]}"></Svgg></td>
                    <td @click="od('fluctuation')">收益波动率<Svgg :active="orderlist[5]" :class="{trans:order&&orderlist[0]}"></Svgg></td>
                    <td @click="od('sharp')">夏普比率<Svgg :active="orderlist[6]" :class="{trans:order&&orderlist[0]}"></Svgg></td> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,index) in showimglist" :key="index" class="d1">
                      <td>
                        <div style=" text-overflow: ellipsis;white-space: nowrap;word-break: break-all;overflow: hidden;width:230px" @mouseenter="showtip($event,item)" @mouseleave="hidetip()" @click="al()">
                          <div style="display:inline">
                            {{item.listInfo.strategyName}}
                          </div>
                        </div>
                      </td>
                      <td>{{item.listInfo.annualProfit.toFixed(2)}}%</td>
                      <td>{{item.listInfo.weekProfit.toFixed(2)}}%</td>
                      <td>{{item.listInfo.monthProfit.toFixed(2)}}%</td>
                      <td>{{item.listInfo.threeYearProfit.toFixed(2)}}%</td>
                      <td>{{item.listInfo.maxFallBack.toFixed(2)}}%</td>
                      <td>{{item.listInfo.fluctuation.toFixed(2)}}%</td>
                      <td>{{item.listInfo.sharp.toFixed(2)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" v-if="showloading">
      <img src="../assets/img/loading.gif" alt="" style="position:absolute;top:42%;left:50%;">
    </div>
    <BaseHover :message="message" v-show="!!message" class="basehover" :style="{left:left+'px',top:top+'px'}"></BaseHover>
    <BaseAlert @close="close" v-if="showalert"></BaseAlert>
  </div>
</template>
<script>
import axios from "axios";
import Svgg from "../components/Svgg.vue"
import Basecharts from "../components/Basecharts.vue"
import BaseHover from "../components/BaseHover.vue"
import BaseAlert from "../components/BaseAlert.vue"
import res from '../assets/js/data.json'
export default {
components:{
  Basecharts,BaseHover,BaseAlert,Svgg
},
  watch: {
    "$route.query.type": {
      handler: function (newd) {
        console.log(newd)
        !!newd&&this.choosestrategy(newd)
      },
    },
  },
created () {
    // console.log(res)
    // this.datachange(res.data.ListInfo)
    // !!this.$route.query.type&&this.choosestrategy(this.$route.query.type)
    // this.showloading=false
    this.orderlist=[
      {
        active:false,
        name:'年化收益',
        ename:'annualProfit',
      },
      {
        active:false,
        name:'近一周收益',
        ename:'weekProfit',
      },
      {
        active:false,
        name:'近一个月收益',
        ename:'monthProfit',
      },
      {
        active:false,
        name:'近三年收益',
         ename:'threeYearProfit',
      },
      {
        active:false,
        name:'最大回撤',
         ename:'maxFallBack',
      },
      {
        active:false,
        name:'收益波动率',
         ename:'fluctuation',
      },
      {
        active:false,
        name:'夏普比率',
         ename:'sharp',
      },
    ]
        axios.get('https://pc-merge.wenhua.com.cn/wh10/f10/MApi/Strategy/StrategyList').then(res=>{
          this.showloading=false
          console.log(res,res.data.ListInfo);
          this.datachange(res.data.ListInfo)
         !!this.$route.query.type&&this.choosestrategy(this.$route.query.type)
      })
},
data(){
  return{
    mylist:[],
    mytag:[],
    ifshowtable:false,
    showimglist:[],
    strategyList:['股票策略','行业策略','商品策略','可转债策略'],
    strategyid:0,
    showtag:[],
    message:'',
    left:0,
    top:0,
    showalert:false,
    showloading:true,
    oldordername:'',
    order:'',
    orderlist:[],
  }
},
methods:{
  al(){
    this.showalert=true
    
  },
  close(){
    this.showalert=false
  },
  // compared(a,b,name){
  //   return a[name]-b[name]
  // },
  od(name,idx){
    // this.showimglist
    this.orderlist.forEach((el)=>{
      el.active=false
    })
    this.orderlist[idx].active=true
    if(this.oldordername==name){
      this.order=!this.order
    }
    else{
      this.order=false
    }
    this.oldordername=name
    if(this.order!=false){
      this.showimglist.sort(function(a, b){return b.listInfo[name]-a.listInfo[name]})
    }
    else{

      this.showimglist.sort(function(a, b){return a.listInfo[name]-b.listInfo[name]})
    }
  },
  showtip(event,item){
     var e = event || window.event;
       var scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
       var scrollY = document.documentElement.scrollTop || document.body.scrollTop;
       this.left = e.pageX || e.clientX + scrollX;
       this.top = e.pageY || e.clientY + scrollY;
    this.message=item.listInfo.strategyName
  },
  hidetip(){
    this.message=''
  },
  choosetag(item,index){
    this.showimglist=this.mylist[this.strategyid][item.it]
    this.mytag[this.strategyid].forEach((el)=>{
      el.active=false
    })
    console.log(this.mytag[this.strategyid])
    this.mytag[this.strategyid][index].active=true
    this.showtag=this.mytag[this.strategyid]
    this.$forceUpdate()
  },
  choosestrategy(index){
    this.strategyid=index
    this.showtag=this.mytag[index]
    var ac=this.showtag.filter((da)=>{
      return da.active==true
    })
    this.showimglist=this.mylist[index][ac[0].it]
    this.$forceUpdate()
  },
  datachange(indata){
    for(var le=0;le<this.strategyList.length;le++){
      this.mylist.push({'全部':[]})
      this.mytag.push([{it:'全部',active:true}])
    }
    indata.forEach((el,idx)=>{
      this.mylist[el.strategyType]['全部'].push(el)
      el.listInfo.tag.forEach((els)=>{
        if(this.mytag[el.strategyType].filter((da)=>{
          return da.it==els
        }).length==0){
          this.mytag[el.strategyType].push({it:els,active:false})
          this.mylist[el.strategyType][els]=[]
          this.mylist[el.strategyType][els].push(el)

        }
        else{
          this.mylist[el.strategyType][els].push(el)
        }
      })
    })
    this.showtag=this.mytag[0]
    this.showimglist=this.mylist[0]['全部']
    console.log(this.mylist,this.mytag)
  },
  changetaimg(bo){
    this.ifshowtable=bo
  }
}
};
</script>
<style scoped>
.trans{
  transform: rotate(180deg);
}
.container {
  padding-top: 50px;
  height: 100%;
  min-height: 400px;
  background: url(../assets/img/bgone.jpg)no-repeat top,url(../assets/img/bgonepp.jpg)repeat-y center top 900px;
  text-align: left;
}
.loading{
  position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
}
.content {
  color: rgb(132, 133, 135);
  border-radius: 10px  10px 0 0;
  width: 100%;
  height: 100%;
  min-height: 600px;
  /* margin-left: 40px; */
  background: #fff;
  box-shadow: -1px 0px 3px 0px rgba(209, 222, 238, 0.9);
  padding-top: 23px;
  font-size: 17px;
}
.tab {
  width: 144px;
}
.tab_title {
  font-size: 20px;
  font-weight: 570;
  line-height: 40px;
  padding-left: 8px;
  color: #323232;
  margin-top: 25px;
}
.underline {
  width: 30px;
  height: 2px;
  background: #323232;
  margin-left: 8px;
}
.tab ul {
  margin-top: 20px;
}
.tab ul li {
  color: #999da6;
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  width: 127px;
  padding-left: 12px;
  background: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 0 50px 50px 0;
  padding-right: 18px;
}
.tab ul li:hover{
  color: #3b78f7;
}
.tab ul li.active {
  color: #3b78f7;
  background-image: linear-gradient(90deg,#f9feff,#e9f6ff,#dfebf7);
  background-image: -moz-linear-gradient(90deg,#f9feff,#e9f6ff,#dfebf7);
  box-shadow: #d1d6e5 1px 1px 5px 2px;
}
.text-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 1025px;
  margin: 0 auto;
}
.text-txt{
  padding-top: 5px;
  font-size: 15px;
  min-height: 600px;
  background: #f6f9fe;
  margin-top: 3px;
  padding-bottom: 40px;
}
.tit-left{
  display: flex;
  flex-direction: row;
}
.right-tip{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 1005px;
  margin: 0 auto;
  cursor: pointer;
}
.right-tip div{
  padding: 2px 4px;
}
/* .right-tip div.active{
  color: white;
  background: #4b63f7;
} */
.tit-left-item{
  cursor: pointer;
  /* padding: 6px 18px; */
  position: relative;
  color: #181818;
}
/* .tit-left-item.active{
  color: #4b63f7;
  border-bottom: 3px solid #4b63f7;
} */
.txt-left-item{
  cursor: pointer;
  margin:0  10px;
  padding: 3px 0px;
  font-size: 16px;
}
  .txt-left-item:hover{
    color: #536b99;
  }
.txt-left-item.active{
  color: #5268cd;
  border-bottom: 3px solid #5268cd;
}
.txt-img{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 1029px;
  margin: 0 auto;
  margin-top: 5px;
}
.base-ech{
  margin: 0px 12px;
  margin-bottom: 26px;
}
.txt-title{
  display: flex;
  flex-direction: row;
  /* margin-left: 50px; */
}
.txt-table{
  margin-top: 5px;
}
.txt-table td{
  padding:11px 0;
  padding-right: 15px;
}
/* .txt-table td:last-child{
  padding-right: 5px;
} */
.txt-table td:first-child{
    color: #236ea8;
    padding-left: 18px;
    text-align: left ;
}
.txt-table tbody td:first-child{
  cursor: pointer;
}
.txt-table tbody td:first-child:hover div div{
  border-bottom: 1px solid #ff6503;
  color: #ff6503;
}
.txt-table tbody tr:nth-child(2n) {
  background: #f5f8fd;
}
.txt-table thead tr{

  background-image: linear-gradient(90deg,#cadbf9,#bfd9f2,#d6e9f7);
  background-image: -moz-linear-gradient(90deg,#cadbf9,#bfd9f2,#d6e9f7);
}
.txt-table thead tr td{
  padding-top: 13px;
   padding-bottom: 13px;
   color: #4b5f78 !important;
}
.txt-table tbody tr{
   border: 1px solid #e3e3e3;
   background: white;
}
.basehover{
  position: absolute;
}
</style>