<template>
<div>
    <div class="line"></div>
    <div class="bottom" style="padding-top:0">
        <div class="symbol"></div>
        <span class="symboltext">免责说明:</span>
        <div class="bottomText">本案例仅用于说明WT10量化功能的使用方法，不构成任何投资建议。</div>
    </div>
</div>
</template>

<style scoped src="./common.css"></style>
<script>
export default {

}
</script>

<style>

</style>